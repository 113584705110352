import analytics from '@capturi/analytics'
import { Card, CardContent, PlaySnippetsButton } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useMemo, useState } from 'react'

import { KeyTopicsDrawer } from '../../../components/KeyTopics/Drawer'
import EmptyWidget from '../FailedStates/EmptyWidget'
import { HalfCircleDonutChartWithLegend } from './components/HalfCircleDonutChart'
import Digest from './components/KeyTopicsDigest'
import { KeyTopicsList } from './components/KeyTopicsList'
import { KeyTopicsWidgetReadySelector } from './selector'

export const KeyTopicsWidgetCardSkeleton: React.FC = () => {
  return (
    <Card mt={2} h="520px">
      <CardContent h="100%">
        <HStack alignItems="stretch" gap={4} w="100%" h="100%">
          <VStack width="300px">
            <Skeleton width="100%" height="12" borderRadius={4} />
            <Skeleton width="100%" height="12" borderRadius={4} />
            <Skeleton width="100%" height="12" borderRadius={4} />
            <Skeleton width="100%" height="12" borderRadius={4} />
            <Skeleton width="100%" height="12" borderRadius={4} />
            <Skeleton width="100%" height="12" borderRadius={4} />
          </VStack>

          <Divider orientation="vertical" />
          <VStack w="100%" alignItems="flex-start">
            <VStack w="100%" alignItems="flex-start" gap={1}>
              <Skeleton height="20px" width="15%" />
              <Skeleton height="14px" width="40%" />
            </VStack>
            <HStack h="100%" w="100%" alignItems="flex-start">
              <Skeleton height="100%" width="260px" />

              <VStack w="300px" h="100%" flexGrow="1" alignItems="flex-start">
                <Skeleton height="81px" width="100%" />
                <Skeleton height="100%" width="100%" />
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </CardContent>
    </Card>
  )
}

const NoKeyTopicsWidgetCard: React.FC = () => {
  return (
    <Card mt={2}>
      <CardContent>
        <VStack>
          <EmptyWidget
            heading={t`No top conversation drivers`}
            body={t`With the current selection there isn't any top conversation drivers.`}
          />
        </VStack>
      </CardContent>
    </Card>
  )
}

const KeyTopicSummaryText: React.FC<{
  keyTopic: KeyTopicsWidgetReadySelector['keyTopicClusters'][number]
}> = React.memo(({ keyTopic }) => {
  const renderUnderlyingTopics = () => {
    const length = keyTopic.graphData.length
    switch (length) {
      case 0:
        return null
      case 1:
        return (
          <Trans>
            The largest underlying topic is{' '}
            <chakra.span fontWeight="medium">
              {keyTopic.graphData[0].label}
            </chakra.span>
            .
          </Trans>
        )
      case 2:
        return (
          <Trans>
            The two largest underlying topics are{' '}
            <chakra.span fontWeight="medium">
              {keyTopic.graphData[0].label}
            </chakra.span>{' '}
            and{' '}
            <chakra.span fontWeight="medium">
              {keyTopic.graphData[1].label}
            </chakra.span>
            .
          </Trans>
        )
      default:
        return (
          <Trans>
            The three largest underlying topics are{' '}
            <chakra.span fontWeight="medium">
              {keyTopic.graphData[0].label}
            </chakra.span>
            ,{' '}
            <chakra.span fontWeight="medium">
              {keyTopic.graphData[1].label}
            </chakra.span>{' '}
            and{' '}
            <chakra.span fontWeight="medium">
              {keyTopic.graphData[2].label}
            </chakra.span>
            .
          </Trans>
        )
    }
  }

  return (
    <Text fontSize="md" textColor="gray.600">
      <Trans>
        <chakra.span fontWeight="medium">{keyTopic.label}</chakra.span> and
        related topics makes up {keyTopic.percentage.toFixed()}% of all
        conversations.
      </Trans>{' '}
      {renderUnderlyingTopics()}
    </Text>
  )
})

export const KeyTopicsWidgetCard: React.FC<{
  keyTopicsSessionUid: string | undefined
  data: KeyTopicsWidgetReadySelector
}> = ({ keyTopicsSessionUid, data }) => {
  const [openKeyTopicsDrawer] = useModal(KeyTopicsDrawer)

  const [selectedClusterId, setSelectedClusterId] = useState<
    string | undefined
  >(data.keyTopicClusters[0]?.id)

  if (
    data &&
    data.keyTopicClusters.length > 0 &&
    selectedClusterId === undefined
  ) {
    setSelectedClusterId(data.keyTopicClusters[0].id)
  }

  const selectedKeyTopic = useMemo(
    () => data.keyTopicClusters.find((topic) => topic.id === selectedClusterId),
    [data, selectedClusterId],
  )

  if (data.keyTopicClusters.length === 0) return <NoKeyTopicsWidgetCard />

  return (
    <Card mt={2}>
      <CardContent h="100%">
        <HStack alignItems="stretch" gap={4} h="100%" w="100%">
          <Box width={60}>
            <KeyTopicsList
              data={data.keyTopicClusters}
              selectedKeyTopicId={selectedClusterId}
              setSelectedKeyTopicId={setSelectedClusterId}
            />
          </Box>
          <Box
            backgroundColor="gray.200"
            position="relative"
            w="1px"
            top="0"
            bottom="0"
          />
          <VStack w="100%" alignItems="flex-start">
            <Flex gap="8" justifyContent="space-between" w="100%">
              <Flex flexDir="column" w="200px" flexGrow="1">
                <Text
                  fontSize="md"
                  fontWeight="medium"
                  textColor="gray.800"
                  textTransform="uppercase"
                >
                  {selectedKeyTopic?.label}
                </Text>
                <Text
                  fontSize="xs"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  textColor="gray.600"
                  w="100%"
                >
                  {selectedKeyTopic?.keyTopics
                    ?.slice(
                      1,
                      5,
                    ) /* Skip first topic and stop at the fifth, as it's in label */
                    .map((topic) => topic.name)
                    .join(', ')}
                  {selectedKeyTopic &&
                    selectedKeyTopic?.keyTopics.length > 5 && (
                      <Trans>, etc.</Trans>
                    )}
                </Text>
              </Flex>
              <PlaySnippetsButton
                onClick={(e) => {
                  e.stopPropagation()
                  if (!(selectedKeyTopic && keyTopicsSessionUid)) return
                  openKeyTopicsDrawer({
                    topicId: selectedKeyTopic.id,
                    sessionUid: keyTopicsSessionUid,
                    referenceBenchmark: data.benchmark,
                    clusterName: selectedKeyTopic.label,
                    topics: selectedKeyTopic.keyTopics,
                  })
                  analytics.event('top_drivers_drawer_opened')
                }}
                label={t`Discover`}
              />
            </Flex>
            <Grid w="100%" h="100%" gap={4} templateColumns="262px 1fr">
              <GridItem>
                <Box
                  borderColor="gray.200"
                  borderWidth="1px"
                  borderRadius={4}
                  pt={3}
                  px={4}
                  pb={4}
                  w="100%"
                  h="100%"
                >
                  <VStack alignItems="flex-start" gap={2} w="100%" h="100%">
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      textColor="gray.600"
                    >
                      <Trans>Top 3 subtopics</Trans>
                    </Text>
                    <HalfCircleDonutChartWithLegend
                      data={selectedKeyTopic?.graphData}
                      innerRadius={50}
                      outerRadius={100}
                    />
                  </VStack>
                </Box>
              </GridItem>
              <GridItem>
                <Flex flexDirection="column" h="100%" gap={2}>
                  <Box
                    flexGrow={0}
                    borderColor="gray.200"
                    borderWidth="1px"
                    borderRadius={4}
                    py={3}
                    px={4}
                  >
                    <Text fontSize="sm" fontWeight="medium">
                      <Trans>Summary</Trans>
                    </Text>
                    {selectedKeyTopic && (
                      <KeyTopicSummaryText keyTopic={selectedKeyTopic} />
                    )}
                  </Box>
                  <Box flexGrow={1} position="relative" w="100%" h="100%">
                    <Digest
                      keyTopicsSessionUid={keyTopicsSessionUid}
                      keyTopicClusterId={selectedClusterId}
                      keyTopicClusterLabel={selectedKeyTopic?.label}
                      keySubTopicClusters={
                        selectedKeyTopic?.digestSubTopicClusters
                      }
                    />
                  </Box>
                </Flex>
              </GridItem>
            </Grid>
          </VStack>
        </HStack>
      </CardContent>
    </Card>
  )
}
