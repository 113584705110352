import { RequestOptions } from '@capturi/request'

import {
  DeleteIgnoredTrackerPhraseRequestModel,
  IgnoredTrackerPhraseRequestModel,
} from './models'

const DEFAULT_API_VERSION = '3.3'

const controller = 'conversations'
const baseQuery = {
  'api-version': DEFAULT_API_VERSION,
}

export const conversationsAPI = {
  getConversations: (excludeDeleted?: boolean): RequestOptions => ({
    url: `${controller}/list`,
    query: {
      ...baseQuery,
      excludeDeleted,
    },
    method: 'post',
  }),
  getConversationsMatchCount: (excludeDeleted?: boolean): RequestOptions => ({
    url: `${controller}/count`,
    query: {
      ...baseQuery,
      excludeDeleted,
    },
    method: 'post',
  }),
  getConversation: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}`,
    query: baseQuery,
  }),
  getConversationAudioInfo: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}/audio`,
    query: baseQuery,
  }),
  deleteConversation: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}`,
    query: baseQuery,
    method: 'delete',
  }),
  wordSearch: (uid: string, query: string): RequestOptions => ({
    url: `${controller}/${uid}/searchV3`,
    query: {
      ...baseQuery,
      query,
    },
  }),
  getSentiment: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}/sentiment`,
    query: baseQuery,
    method: 'get',
  }),
  exportCSV: (
    // biome-ignore lint/suspicious/noExplicitAny: legacy
    filterModel: { [key: string]: any },
    excludeDeleted: boolean,
  ): RequestOptions => ({
    url: `${controller}/csv`,
    query: {
      ...baseQuery,
      excludeDeleted,
    },
    method: 'post',
    json: filterModel,
  }),
  createIgnoredTrackerPhrasesEntry: (
    uid: string,
    models: IgnoredTrackerPhraseRequestModel[],
  ): RequestOptions => ({
    url: `${controller}/${uid}/ignored-phrases`,
    query: baseQuery,
    method: 'post',
    json: models,
  }),
  removeIgnoredTrackerPhrasesEntry: (
    uid: string,
    models: DeleteIgnoredTrackerPhraseRequestModel[],
  ): RequestOptions => ({
    url: `${controller}/${uid}/delete-ignored-phrases`,
    query: baseQuery,
    method: 'post',
    json: models,
  }),
  //Changes the team on conversations from fromDate until now
  syncTeamOnConversations: (
    userUid: string,
    newTeamUid: string,
    fromDate: Date,
  ): RequestOptions => ({
    url: `${controller}/change-user-team`,
    query: baseQuery,
    method: 'post',
    json: {
      userUid: userUid,
      newTeamUid: newTeamUid,
      fromDate: fromDate,
    },
  }),
  downloadTranscription: (uid: string, anonymize: boolean): RequestOptions => {
    const url = `${controller}/${uid}/transcript`
    const query = {
      ...baseQuery,
      anonymize,
    }
    return {
      url,
      query,
      method: 'get',
    }
  },
}
