import constate from 'constate'
import { isValid, parseISO } from 'date-fns'
import React, { useState } from 'react'

import { adjustPeriodForDSTOverlap } from '../utils/dstAdjustments'
import { NamedPeriod, PeriodDefinitions } from './constants'
import {
  FixedPeriodDefinition,
  Period,
  PeriodDefinition,
  parseFixedPeriod,
} from './period'

export function periodDefinitionToNamedPeriod(
  fromInclusive: Date,
  toInclusive: Date,
): NamedPeriod | undefined {
  const referenceDate = new Date()

  for (const [key, namedPeriodDefinition] of Object.entries(
    PeriodDefinitions,
  )) {
    const namedPeriod = namedPeriodDefinition?.create(referenceDate)
    const { fromInclusive: namedFromInclusive, toInclusive: namedToInclusive } =
      adjustPeriodForDSTOverlap(namedPeriod)

    if (
      fromInclusive.getTime() === namedFromInclusive.getTime() &&
      toInclusive.getTime() === namedToInclusive.getTime()
    ) {
      return key as NamedPeriod
    }
  }
  return undefined
}

function parseInitialPeriod(
  period: PeriodDefinition | NamedPeriod | string | undefined,
  fallbackPeriod: PeriodDefinition,
): PeriodDefinition {
  if (period === undefined) return fallbackPeriod
  if (typeof period === 'string') {
    // 1. Parse as Date
    const parsedDate = parseISO(period)
    if (isValid(parsedDate)) {
      return new FixedPeriodDefinition(parsedDate, parsedDate)
    }
    // 2. Parse as NamedPeriod
    if (Object.values(NamedPeriod).includes(period as NamedPeriod)) {
      return PeriodDefinitions[period as NamedPeriod]
    }
    // 3. Parse as FixedPeriod
    const fixedPeriod = parseFixedPeriod(period)
    return fixedPeriod ?? fallbackPeriod
  }
  return period
}

export type FilterPeriodProviderProps = {
  /** Default period for this provider */
  defaultPeriod?: PeriodDefinition
  /** Initial period to set */
  initialPeriod?: PeriodDefinition | NamedPeriod | string
  /** Callback when period changes */
  onPeriodChange?: (definition: PeriodDefinition) => void
}

function useFilterPeriod({
  defaultPeriod = PeriodDefinitions[NamedPeriod.Last7Days],
  initialPeriod,
  onPeriodChange,
}: FilterPeriodProviderProps): {
  periodDef: PeriodDefinition
  setPeriodDef: (period: PeriodDefinition) => void
  /**
   * Reset period to its initial value
   */
  resetPeriodToDefault: () => void
  period: Period
  definitions: typeof PeriodDefinitions
} {
  const [periodDef, _setPeriodDef] = useState<PeriodDefinition>(() => {
    return parseInitialPeriod(initialPeriod, defaultPeriod)
  })

  const setPeriodDef = React.useCallback(
    (periodDef: PeriodDefinition): void => {
      _setPeriodDef(periodDef)
      onPeriodChange?.(periodDef)
    },
    [onPeriodChange],
  )

  return React.useMemo(() => {
    const period = periodDef.create(new Date())
    return {
      periodDef,
      setPeriodDef: (period: PeriodDefinition) => setPeriodDef(period),
      resetPeriodToDefault: (): void => setPeriodDef(defaultPeriod),
      period,
      definitions: PeriodDefinitions,
    }
  }, [periodDef, setPeriodDef, defaultPeriod])
}

const [FilterPeriodProvider, useFilterPeriodContext] = constate(useFilterPeriod)

export { FilterPeriodProvider, useFilterPeriodContext }
