import { useUsers } from '@capturi/stores'
import { t } from '@lingui/macro'
import React from 'react'

import { FilterValueLabel } from '../../components/FilterValueLabel'

export const UsersValueLabel: React.FC<{ uids?: string[] | null }> = ({
  uids,
}) => {
  const { getUserByUid } = useUsers()
  const names = (uids ?? []).map((uid) => getUserByUid(uid).name)
  return <FilterValueLabel name={t`Employee`} value={names} />
}
