import {
  CustomNumberPropSettings,
  CustomPropSettings,
  OrganizationMetadataSettings,
  useOrganizationSettings,
} from '@capturi/api-organization'
import { useCurrentUser } from '@capturi/core'
import React from 'react'

import { FilterCriteriaComponentBaseProps } from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import { CustomNumberPropRange } from './customNumberProp/CustomNumberPropRange'
import { CustomNumberPropSelect } from './customNumberProp/CustomNumberPropSelect'
import { CustomPropSelect } from './customProp/CustomPropSelect'
import { CustomPropText } from './customProp/CustomPropText'

export type CustomPropFilterComponentProps =
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  FilterCriteriaComponentBaseProps<any> & {
    filterKey: keyof OrganizationMetadataSettings
  }

export function CustomPropFilterComponent({
  filterKey,
  ...props
}: CustomPropFilterComponentProps): React.ReactElement | null {
  const currentUser = useCurrentUser()
  const { data } = useOrganizationSettings()

  const locale = currentUser.language
  if (filterKey.startsWith('customNumberProp')) {
    const settings = data?.metadata[filterKey] as CustomNumberPropSettings
    if (settings.filterType === 'Dropdown') {
      return (
        <CustomNumberPropSelect
          {...props}
          settings={settings}
          locale={locale}
        />
      )
    }
    return (
      <CustomNumberPropRange {...props} settings={settings} locale={locale} />
    )
  }
  const settings = data?.metadata[filterKey] as CustomPropSettings
  if (settings.filterType === 'Dropdown') {
    return <CustomPropSelect {...props} settings={settings} locale={locale} />
  }
  return <CustomPropText {...props} settings={settings} locale={locale} />
}
