import { Card, CardBody, CardHeader } from '@chakra-ui/react'
import React, { PropsWithChildren } from 'react'

const OverviewCard: React.FC<
  PropsWithChildren & { title: string; onClick: () => void }
> = ({ title, children, onClick }) => {
  return (
    <Card
      flex="50%"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="4px"
      mb={4}
      boxShadow="none"
    >
      <CardHeader
        onClick={onClick}
        cursor="pointer"
        pt={4}
        pl={4}
        pb={children ? 2 : 4}
        fontWeight="medium"
        fontSize="sm"
        color="gray.600"
      >
        {`${title} >`}
      </CardHeader>
      {children && (
        <CardBody overflowY="auto" pt={0}>
          {children}
        </CardBody>
      )}
    </Card>
  )
}

export default OverviewCard
