import { t } from '@lingui/macro'

import { Range } from './customNumberProp/types'

export function getLocalizedLabel(
  labels: Record<string, string> | undefined,
  locale: string,
): string | undefined {
  if (labels === undefined) return undefined
  return labels[locale] ?? Object.values(labels)[0]
}

export function isRangeMatch(
  a: Range | null | undefined,
  b: Range | null | undefined,
): boolean {
  if (a == null || b == null) return false
  return a.min === b.min && a.max === b.max
}

export const formatRange = (
  min: number | undefined | null,
  max: number | undefined | null,
): string => {
  if (min != null && min === max) {
    return String(min)
  }
  if (min != null && max != null) {
    return t`Between ${min} and ${max}`
  }
  if (min != null) {
    return t`Minimum ${min}`
  }
  if (max != null) {
    return t`Maximum ${max}`
  }
  return ''
}
