import analytics from '@capturi/analytics'
import { useAudioContext } from '@capturi/audio'
import { useFeatureFlags } from '@capturi/feature-flags'
import { getErrorMessage } from '@capturi/request'
import { useSingleUser } from '@capturi/stores'
import { DurationInput, Spinner, useToast } from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Stack,
  Textarea,
} from '@chakra-ui/react'
import { Plural, Trans, t } from '@lingui/macro'
import React, { ChangeEvent, useState } from 'react'

import { addNewComment } from '../Hooks/useComments'
import Modal from '../components/Modal'
import RecipientsSelector from './RecipientsSelector'

export type InitialFocus = 'recipients' | 'comment'

export type CommentModalOptions = {
  conversationUid: string
  initialTime: number
  initialRecipientUserUids?: string[]
  initialText?: string
  initialFocus?: InitialFocus
  agentUid?: string
  /**
   * This is a `Velkommen` org. feature:
   * Comments are prefixed with tracker name and phrase info in the following format:
   * `<tracker-name; phrase>`
   * Not very appropriate!
   * These kind of org. specific features should not be mixed into the code like this.
   */
  hiddenTextPrefix?: string
  onClose?: () => void
  onCancel?: () => void
}

export type CreateCommentModalProps = CommentModalOptions &
  BaseModalComponentProps<void>

const CreateCommentModal: React.FC<CreateCommentModalProps> = ({
  initialTime,
  conversationUid,
  initialRecipientUserUids = [],
  initialText = '',
  initialFocus = 'recipients',
  hiddenTextPrefix,
  agentUid,
  onClose,
  onCancel,
}) => {
  const { _Velkommen_trackerExampleCommentPrefixFeature } = useFeatureFlags()

  React.useEffect(() => {
    analytics.modalview('CreateCommentModal')
    analytics.event('comment_conversationDetails_openCreateComment')
  }, [])
  const toast = useToast()
  const { isPlaying, pause } = useAudioContext()
  if (isPlaying) {
    pause()
  }

  const [comment, setComment] = useState(initialText)
  const [time, setTime] = useState(initialTime)
  // Org. "Velkommen" should not send a notification by default
  const [sendNotification, setSendNotification] = useState(
    !_Velkommen_trackerExampleCommentPrefixFeature,
  )

  const [recipientUserUids, setRecipientUserUids] = useState<string[]>(
    initialRecipientUserUids,
  )

  const [inProgress, setInProgress] = useState(false)

  const commentRef = React.useRef<HTMLTextAreaElement>(null)
  const recipientsRef = React.useRef<HTMLDivElement>(null)

  const { name } = useSingleUser(agentUid)
  const { mutate: addComment } = addNewComment()

  const getInitialFocusRef = (): React.RefObject<HTMLElement> =>
    initialFocus === 'recipients' ? recipientsRef : commentRef

  const submit = (): void => {
    setInProgress(true)
    try {
      const trimmedComment = (hiddenTextPrefix ?? '') + comment.trim()

      const payload = {
        when: time,
        text: trimmedComment,
        recipientUserUids,
        sendNotification,
      }
      addComment({ conversationUid, payload })

      analytics.event('comment_conversationDetails_create', {
        numberOfRecipients: recipientUserUids.length,
        sendNotification,
        commentLength: trimmedComment.length,
        startTime: time,
        agent: name,
        comment: trimmedComment,
      })
      setInProgress(false)
      toast({ title: t`Comment added`, status: 'success' })
      onClose()
    } catch (error) {
      setInProgress(false)
      onCancel?.()
      const errorMsg = getErrorMessage(error)
      toast({
        title: t`This did not go as we expected`,
        description: errorMsg,
        status: 'error',
      })
    }
  }

  return (
    <Modal
      onClose={onClose}
      initialFocusRef={getInitialFocusRef()}
      header={<Trans>Add comment</Trans>}
      subheader={
        <Trans>The comment will only be visible for the recipients</Trans>
      }
      onSubmit={submit}
      submitText={<Trans>Add</Trans>}
      inProgress={inProgress}
      isValid={!!(recipientUserUids.length && comment)}
    >
      <React.Suspense fallback={<Spinner display="block" m="5rem auto" />}>
        <Stack spacing={4}>
          <FormControl>
            <FormLabel>
              <Trans>To</Trans>
            </FormLabel>
            <Box ref={recipientsRef}>
              <RecipientsSelector
                initialRecipientUserUids={recipientUserUids}
                conversationUid={conversationUid}
                onChange={setRecipientUserUids}
                autoFocus={initialFocus === 'recipients'}
              />
            </Box>
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="comment">
              <Trans>Comment</Trans>
            </FormLabel>
            <Textarea
              ref={commentRef}
              id="comment"
              placeholder={t`Your comment ...`}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>): void =>
                setComment(e.target.value)
              }
              value={comment}
              height="40"
              isDisabled={inProgress}
            />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="timestamp">
              <Trans>Time in conversation</Trans>
            </FormLabel>
            <DurationInput
              id="timestamp"
              onChange={setTime}
              value={time}
              isDisabled={inProgress}
            />
          </FormControl>
          <FormControl>
            <Checkbox
              mt="3"
              isChecked={sendNotification}
              onChange={(e) => setSendNotification(e.target.checked)}
              isDisabled={inProgress}
            >
              <Plural
                value={recipientUserUids.length}
                one="Notify recipient"
                other="Notify recipients"
              />
            </Checkbox>
          </FormControl>
        </Stack>
      </React.Suspense>
    </Modal>
  )
}

export default function CreateCommentModalWrapper(
  props: React.ComponentProps<typeof CreateCommentModal>,
): React.ReactElement {
  return <CreateCommentModal {...props} />
}
