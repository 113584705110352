import { TextFilterValuesRequestModel } from '@capturi/api-filters'
import request, { ResponseError } from '@capturi/request'
import {
  InfiniteData,
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'

import {
  Case,
  CaseListIncludingTrackerHitsResponseModel,
  CaseListResponseModel,
  ListCase,
} from '../models'

export type CasesIncludingTrackerHitsResponse = {
  cases: CaseListIncludingTrackerHitsResponseModel[]
  continuationToken: string
}

const sortDirection = {
  descending: 'Descending',
  ascending: 'Ascending',
}

const getNextPageParam = (lastPage: { continuationToken: string | null }) =>
  lastPage.continuationToken

const selectCases = (
  data: InfiniteData<CaseListResponseModel>,
): InfiniteData<ListCase[]> => ({
  pages: data.pages.map((page) => page.cases),
  pageParams: [],
})

const selectTrackerHitCases = (
  data: InfiniteData<CasesIncludingTrackerHitsResponse>,
): InfiniteData<CaseListIncludingTrackerHitsResponseModel[]> => ({
  pages: data.pages.map((page) => page.cases),
  pageParams: [],
})

const selectorOne =
  (uid: string | undefined): ((data: Case) => Case | undefined) =>
  (data) => {
    if (uid) return data
  }

export const useCase = (
  uid: string | undefined,
): UseQueryResult<Case | undefined, ResponseError> =>
  useQuery({
    queryKey: ['cases', uid],
    queryFn: () => request.get<Case>(`cases/${uid}?api-version=3.3`),
    select: selectorOne(uid),
    enabled: !!uid,
  })

export const useCases = (
  filter: TextFilterValuesRequestModel,
  period: { from: Date; to: Date },
): UseInfiniteQueryResult<InfiniteData<ListCase[], unknown>, ResponseError> =>
  useInfiniteQuery({
    queryKey: ['cases', 'list', filter, period.from, period.to],
    queryFn: async ({ pageParam }) =>
      await request.post<CaseListResponseModel>('cases?api-version=3.3', {
        json: pageParam
          ? { continuationToken: pageParam }
          : {
              ...filter,
              fromInclusive: period.from,
              toInclusive: period.to,
              limit: 100,
              sortDirection: sortDirection.descending,
            },
      }),
    getNextPageParam,
    initialPageParam: null,
    select: selectCases,
  })

export const useCasesIncludingTrackerHits = ({
  trackerUid,
  phrase,
  filter,
  period,
}: {
  trackerUid: string
  phrase?: string
  filter: TextFilterValuesRequestModel
  period: { from: Date; to: Date }
}): UseInfiniteQueryResult<
  InfiniteData<CaseListIncludingTrackerHitsResponseModel[], unknown>,
  ResponseError
> =>
  useInfiniteQuery({
    queryKey: ['cases', trackerUid, filter, period.from, period.to, phrase],
    queryFn: async ({ pageParam }) =>
      await request.post<CasesIncludingTrackerHitsResponse>(
        `cases/including-tracker-hits/${trackerUid}`,
        {
          json: pageParam
            ? { continuationToken: pageParam }
            : {
                ...filter,
                fromInclusive: period.from,
                toInclusive: period.to,
                limit: 30,
                sortDirection: sortDirection.descending,
              },
          query: {
            'api-version': '3.3',
            phrase,
          },
        },
      ),
    getNextPageParam,
    initialPageParam: null,
    select: selectTrackerHitCases,
  })
