import analytics from '@capturi/analytics'
import { ConversationResponseModel, Tracker } from '@capturi/api-conversations'
import { Box, Divider, Stack, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { startTransition, useCallback } from 'react'

import { Hit } from '../Audio/types'
import { useTrackerHitUtils } from '../Hooks/UseTrackerHitUtils'
import { useTrackerHits } from '../Hooks/useTrackerHits'
import TrackerContentsResultsList from '../components/TrackerContentsResultsList'
import TableOfContents from './TableOfContents'
import useTrackerTableContents from './useTrackerTableContents'

type TrackersViewProps = {
  conversation: ConversationResponseModel
  isSearchMode: boolean
  initialSelectedTrackerUid: string | null
  setHits: (hits: Hit[]) => void
}

const TrackersView: React.FC<TrackersViewProps> = ({
  conversation,
  isSearchMode,
  initialSelectedTrackerUid,
  setHits,
}) => {
  const [selectedTracker, setSelectedTracker] = React.useState<string | null>(
    initialSelectedTrackerUid ?? null,
  )
  const { trackerHitMap, trackerHits } = useTrackerHits(conversation)
  const { convertResultToHit, shapeForHit, colorForHit } = useTrackerHitUtils()
  const handleSelectTracker = useCallback((tracker: Tracker | null) => {
    startTransition(() => {
      setSelectedTracker(tracker?.uid ?? null)
    })
  }, [])

  const contents = useTrackerTableContents(
    conversation?.trackerHits ?? [],
    selectedTracker,
    conversation?.ignoredPhrases,
  )

  React.useEffect(() => {
    const selectedHits = contents.results.map((hit) => {
      return convertResultToHit(
        hit,
        selectedTracker != null,
        shapeForHit(hit, contents),
        colorForHit(hit, contents),
        false,
      )
    })
    const extraHits = contents.extraResults.map((hit) => {
      return convertResultToHit(
        hit,
        false,
        shapeForHit(hit, contents),
        colorForHit(hit, contents),
        true,
      )
    })

    const hits = [...extraHits, ...selectedHits]
    setHits?.(hits)
  }, [
    setHits,
    contents,
    selectedTracker,
    convertResultToHit,
    shapeForHit,
    colorForHit,
  ])

  const totalHitCount = React.useMemo(() => {
    return Array.from(trackerHitMap.values()).reduce((acc, hit) => {
      return acc + hit.count
    }, 0)
  }, [trackerHitMap])

  const handleSelectAll = React.useCallback(() => {
    analytics.event('analytics_Conversation_selectTracker')
    handleSelectTracker(null)
  }, [handleSelectTracker])

  return (
    <Box ml={2}>
      <Stack isInline spacing={[6, null, null, 8]}>
        {trackerHits.length > 0 && (
          <Box minW="8em" maxW="16em" flex="0 0 auto">
            <Text
              color={
                !isSearchMode && selectedTracker === null ? 'primary.500' : ''
              }
              cursor="pointer"
              fontSize="md"
              h={7}
              onClick={handleSelectAll}
            >
              <Trans>All ({totalHitCount})</Trans>
            </Text>
            <Divider w="100%" mb={2} />
            <TableOfContents
              trackerHits={trackerHits}
              hitMap={trackerHitMap}
              onSelectTracker={handleSelectTracker}
              selectedTrackerUid={
                isSearchMode
                  ? null
                  : selectedTracker
                    ? selectedTracker
                    : initialSelectedTrackerUid
              }
            />
          </Box>
        )}

        <TrackerContentsResultsList
          conversation={conversation}
          contents={contents}
        />
      </Stack>
    </Box>
  )
}

export default TrackersView
