import {
  Divider,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { MdClose, MdSearch } from 'react-icons/md'

type Props = {
  value: string
  onChange: (query: string) => void
  onClear: () => void
}

const MAX_SEARCHABLE_WORDS = 4

const WordSearchInput: React.FC<Props> = ({ value, onChange, onClear }) => {
  const [numberOfWords, setNumberOfWords] = useState<number>(0)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const words = event.target.value.toLowerCase()
    const wordArray = words.trim().split(' ')
    const wordCount = wordArray.length

    if (wordCount <= MAX_SEARCHABLE_WORDS) {
      setNumberOfWords(wordCount)
      onChange(words)
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    const words = event.clipboardData.getData('Text').toLowerCase()
    const wordArray = words.trim().split(' ')
    const wordCount = wordArray.length

    if (wordCount > MAX_SEARCHABLE_WORDS) {
      setNumberOfWords(MAX_SEARCHABLE_WORDS)
      onChange(wordArray.slice(0, 3).join(' '))
    }
  }

  return (
    <InputGroup size="sm" marginLeft="auto" maxW="26rem">
      <InputLeftElement pointerEvents="none">
        <Icon fontSize="14px" as={MdSearch} />
      </InputLeftElement>
      <Input
        w="full"
        type="text"
        placeholder={t`Search for words in the conversation...`}
        value={value}
        onChange={handleChange}
        backgroundColor="white"
        onPaste={handlePaste}
        borderRadius={4}
      />
      {!isEmpty(value) && (
        <InputRightElement width="7rem" justifyContent="flex-end" p={0}>
          <Text fontSize="sm">
            {numberOfWords}/{MAX_SEARCHABLE_WORDS}
          </Text>
          <Divider
            orientation="vertical"
            height="70%"
            borderColor="text"
            ml={2}
          />
          <Icon
            as={MdClose}
            aria-label={t`Clear search`}
            onClick={onClear}
            boxSize={4}
            cursor="pointer"
            mx={3}
            color="text"
          />
        </InputRightElement>
      )}
    </InputGroup>
  )
}

export default WordSearchInput
