const padTokens = (x: number): string => x.toString().padStart(2, '0')
const zeroHoursRegex = /^00:/

export const formatSeconds = (time = 0): string => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor(time / 60) % 60
  const seconds = Math.floor(time) % 60

  return [hours, minutes, seconds]
    .map(padTokens)
    .join(':')
    .replace(zeroHoursRegex, '')
}
