import { theme as baseTheme, theme } from '@chakra-ui/react'

const blue = {
  50: '#E8EAFF',
  100: '#C4CBFE',
  200: '#99A9FF',
  300: '#6787FF',
  400: '#346AFF',
  500: '#004EFC',
  600: '#0045F0',
  700: '#003AE4',
  800: '#002DD9',
  900: '#0015C0',
}

const curiousBlue = {
  50: '#EAF5FA',
  100: '#D5EAF6',
  200: '#ACD5EC',
  300: '#82C0E3',
  400: '#59ABD9',
  500: '#2F96D0',
  600: '#2678A6',
  700: '#1C5A7D',
  800: '#133C53',
  900: '#091E2A',
}

const gray = {
  50: '#fafafa',
  100: '#f5f5f5',
  200: '#eeeeee',
  300: '#e0e0e0',
  400: '#bdbdbd',
  500: '#9e9e9e',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
}

const purple = {
  50: '#F0E8FF',
  100: '#E3D6FF',
  200: '#C6ADFF',
  300: '#A785FF',
  400: '#8D66FF',
  500: '#6334FF',
  600: '#4B26DB',
  700: '#371AB7',
  800: '#251093',
  900: '#19097A',
}

const yellow = {
  50: '#FEF8E3',
  100: '#FDECB9',
  200: '#FCE08D',
  300: '#FCD55F',
  400: '#FCCB3E',
  500: '#FBC22B',
  600: '#FBB426',
  700: '#FAA222',
  800: '#F9921F',
  900: '#F87418',
}

const red = {
  50: '#fff5f5',
  100: '#ffdcdc',
  200: '#ffc2c2',
  300: '#ffa9a9',
  400: '#ff8f8f',
  500: '#FF5C5C',
  600: '#ff2e2e',
  700: '#ff0000',
  800: '#d10000',
  900: '#a30000',
}

const petrolBlue = {
  300: '#A9C6D7',
  500: '#216D97',
  600: '#14608A',
  700: '#08547E',
}

const teal = {
  500: '#2F6C7F',
}

const segments = {
  primary: petrolBlue,
  secondary: {
    300: '#C9D8C5',
    500: '#709966',
    600: '#638C59',
    700: '#57804D',
  },
  tertiary: {
    300: '#FBD999',
    500: '#F59F00',
    600: '#E89200',
    700: '#DC8600',
  },
  quaternary: {
    300: '#F4C99F',
    500: '#E47710',
    600: '#D76A03',
    700: '#CB5E00',
  },
  quinary: {
    300: '#E1B0AD',
    500: '#B33A33',
    600: '#A62D26',
    700: '#9A211A',
  },
}

const primary = curiousBlue
const secondary = gray

const brand = {
  primary: primary[500],
  secondary: secondary[200],
}

const typography = {
  subtitle: gray[500],
}

const colors = {
  ...baseTheme.colors,
  // brand theme
  brand,
  // hues
  blue,
  petrolBlue,
  curiousBlue,
  gray,
  purple,
  yellow,
  red,
  teal,
  primary,
  secondary,
  // data segments
  segmentPrimary: segments.primary[500],
  segmentSecondary: segments.secondary[500],
  segmentTertiary: segments.tertiary[500],
  segmentQuaternary: segments.quaternary[500],
  segmentQuinary: segments.quinary[500],
  segments,

  primaryGradient: 'linear-gradient(180deg, #2F96D0 0%, #2678A6 100%);',
  primaryBackground: teal,

  aiGradient: 'linear-gradient(180deg, #ef3a42 0%, #ffb220 49%, #ff91b3 100%);',

  ...typography,
  border: {
    light: gray[200],
  },
  outlineColor: `${blue[500]}33`,

  /*
    TODO:
    Component theming belongs in the components folder.
    See: `/src/components` and https://chakra-ui.com/docs/theming/component-style
  */
  workspace: {
    bodyBackground: gray[50],
    contentBackground: 'rgba(255, 255, 255, 0.7)',
    sidebar: {
      darkbg:
        'linear-gradient(0deg, rgba(10, 10, 10, 0.1), rgba(10, 10, 10, 0.1)), radial-gradient(137.6% 123.92% at 100% 95.78%, #705257 0%, rgba(112, 82, 87, 0.02) 100%), radial-gradient(118.75% 21.54% at 0% 96.89%, #1B4963 0%, rgba(27, 73, 99, 0.02) 100%), linear-gradient(180deg, #27829C 0%, #0A454D 100%);',
      lightbg:
        'linear-gradient(164deg, rgba(244, 255, 5, 0.00) 0%, rgba(253, 99, 210, 0.06) 42.48%, rgba(56, 255, 231, 0.00) 100%), linear-gradient(184deg, rgba(255, 65, 5, 0.00) 0%, rgba(255, 218, 121, 0.03) 45.31%, rgba(58, 243, 255, 0.06) 64.23%, rgba(56, 207, 255, 0.00) 100%), linear-gradient(180deg, #FFF 0%, #FAFAFA 100%);',
      text: theme.colors.gray[800],
      headerText: theme.colors.gray[600],
    },
    topBar: {
      bg: 'linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(250, 250, 250, 0.7) 90%);',
      border: `1px solid ${gray[300]}`,
      backdropFilter: 'blur(10px)',
      shadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.07)',
    },
  },
  card: {
    bg: gray[50],
    header: {
      title: gray[800],
      subtitle: typography.subtitle,
    },
  },
  wordCloud: {
    darkbg:
      'linear-gradient(0deg, rgba(10, 10, 10, 0.1), rgba(10, 10, 10, 0.1)), radial-gradient(137.6% 123.92% at 100% 95.78%, #705257 0%, rgba(112, 82, 87, 0.02) 100%), radial-gradient(118.75% 21.54% at 0% 96.89%, #1B4963 0%, rgba(27, 73, 99, 0.02) 100%), linear-gradient(180deg, #27829C 0%, #0A454D 100%);',
    miami:
      'linear-gradient(262deg, #FFB757 2.67%, #ED54B4 96.05%), linear-gradient(260deg, rgba(244, 255, 5, 0.00) 23.47%, rgba(253, 99, 210, 0.06) 47.05%, rgba(56, 255, 231, 0.00) 78.99%), linear-gradient(293deg, rgba(255, 65, 5, 0.00) 17.32%, rgba(255, 218, 121, 0.03) 48.33%, rgba(58, 243, 255, 0.06) 61.28%, rgba(56, 207, 255, 0.00) 85.76%), linear-gradient(270deg, #FFF 0%, #FAFAFA 100%);',
  },

  accents: {
    gold: 'linear-gradient(180deg, #FFE052 0%, #FBB925 100%)',
    darkBackground: {
      default:
        'linear-gradient(0deg, rgba(10, 10, 10, 0.1), rgba(10, 10, 10, 0.1)), radial-gradient(137.6% 123.92% at 100% 95.78%, #705257 0%, rgba(112, 82, 87, 0.02) 100%), radial-gradient(118.75% 21.54% at 0% 96.89%, #1B4963 0%, rgba(27, 73, 99, 0.02) 100%), linear-gradient(180deg, #27829C 0%, #0A454D 100%);',
      horizontal:
        'linear-gradient(90deg, #27829C 0%, #0A454D 100%), radial-gradient(124.90% 165.34% at -815.64% 265.78%, #1B4963 0%, rgba(27, 73, 99, 0.02) 100%), radial-gradient(57.57% 52.60% at 174.46% 107.46%, #705257 0%, rgba(112, 82, 87, 0.02) 100%), linear-gradient(0deg, rgba(10, 10, 10, 0.10) 0%, rgba(10, 10, 10, 0.10) 100%)',
    },
    lightBackground: {
      default:
        'linear-gradient(172deg, rgba(244, 255, 5, 0.00) 19.58%, rgba(253, 99, 210, 0.06) 46.97%, rgba(56, 255, 231, 0.00) 84.06%), linear-gradient(205deg, rgba(255, 65, 5, 0.00) 17.4%, rgba(255, 218, 121, 0.03) 46.85%, rgba(58, 243, 255, 0.06) 59.14%, rgba(56, 207, 255, 0.00) 82.39%), linear-gradient(176deg, #FFF 3.66%, #FAFAFA 96.79%);',
      horizontal:
        'linear-gradient(260deg, rgba(244, 255, 5, 0.00) 23.47%, rgba(253, 99, 210, 0.06) 47.05%, rgba(56, 255, 231, 0.00) 78.99%), linear-gradient(293deg, rgba(255, 65, 5, 0.00) 17.32%, rgba(255, 218, 121, 0.03) 48.33%, rgba(58, 243, 255, 0.06) 61.28%, rgba(56, 207, 255, 0.00) 85.76%), linear-gradient(270deg, #FFF 0%, #FAFAFA 100%);',
    },
  },

  cremeYellow: '#F3E9CE',

  // Contextual colors
  success: segments.secondary[500],
  warning: '#FFA35B',
  warningDark: '#C05621',
  warningLight: '#FEEBC8',
  danger: red[500],
  info: '#c5c5c5',
  //used for showing color on positive number
  positive: '#709966',
  //used for showing color on negative number
  negative: '#B33A33',

  // Text
  textMuted: gray[600],
  text: gray[800],

  // open id providers
  google: '#4285F4',
  microsoft: '#DC3E15',
}

export default colors
