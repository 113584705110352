import analytics from '@capturi/analytics'
import {
  formatSecondsToShortHM,
  formatSecondsToShortMS,
} from '@capturi/filters'
import { Card, CardContent, PlaySnippetsButton } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { KeyTopicsDrawer } from '../../../components/KeyTopics/Drawer'
import EmptyWidget from '../FailedStates/EmptyWidget'
import { AnimateChangeInHeight } from '../Shared/AnimateHeight'
import SharedDigest from '../Shared/SharedDigest'
import { KeyTopicsList } from './components/KeyTopicsList'
import {
  RepeatCallsWidgetReadySelector,
  RepeatCallsWidgetSelectorTopics,
} from './selector'

export const RepeatCallsWidgetCardSkeleton: React.FC = () => {
  return (
    <Card mt={2} h="665px">
      <CardContent h="100%">
        <Flex alignItems="stretch" gap={4} w="100%" h="100%">
          <VStack alignItems="flex-start" gap={3}>
            <Skeleton height="20px" width="5rem" mt={1} />
            <VStack width="16.5rem" height="100%" py={3} px={4} gap={2}>
              <Skeleton borderRadius={8} w="100%" h="60px" />
              <Skeleton borderRadius={8} w="100%" h="60px" />
              <Skeleton borderRadius={8} w="100%" h="60px" />
              <Skeleton borderRadius={8} w="100%" h="60px" />
              <Skeleton borderRadius={8} w="100%" h="60px" />
            </VStack>
          </VStack>
          <Divider orientation="vertical" />
          <VStack alignItems="flex-start" gap={3} flexGrow={1}>
            <Skeleton height="20px" width="5rem" mt={1} />
            <Grid
              w="100%"
              h="665px"
              gridColumnGap={2}
              gridRowGap={4}
              templateRows="292px 1fr"
              templateColumns="1fr 228px"
            >
              <GridItem rowSpan={1} colSpan={1}>
                <Skeleton borderRadius={4} height="100%" width="100%" />
              </GridItem>
              <GridItem borderRadius={4} rowSpan={1} colSpan={1}>
                <Skeleton height="100%" width="100%" />
              </GridItem>
              <GridItem borderRadius={4} rowSpan={2} colSpan={2}>
                <Skeleton height="100%" width="100%" />
              </GridItem>
            </Grid>
          </VStack>
        </Flex>
      </CardContent>
    </Card>
  )
}

const NoRepeatCallsWidgetCard: React.FC<{ heading: string; body: string }> =
  memo(({ heading, body }) => {
    return (
      <Card mt={2}>
        <CardContent h="100%">
          <HStack alignItems="stretch" gap={4} w="100%" h="100%">
            <VStack alignItems="stretch" width="100%">
              <EmptyWidget heading={heading} body={body} />
            </VStack>
          </HStack>
        </CardContent>
      </Card>
    )
  })

const SelectedTopicInfoCard: React.FC<{
  selectedKeyTopic: RepeatCallsWidgetSelectorTopics
}> = memo(({ selectedKeyTopic }) => {
  return (
    <Card
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      boxShadow="none"
    >
      <CardContent py={4} px={6}>
        <Text fontSize="xs">
          <Trans>Repeat calls</Trans>
        </Text>
        {selectedKeyTopic.repeatCalls?.amount && (
          <HStack>
            <Text fontSize="2xl" fontWeight="medium">
              {selectedKeyTopic.amount > 0 &&
                (
                  selectedKeyTopic.repeatCalls.amount / selectedKeyTopic.amount
                ).toLocaleString(undefined, {
                  style: 'percent',
                  maximumFractionDigits: 0,
                })}
            </Text>
            <chakra.span color="gray.600">
              ({selectedKeyTopic.repeatCalls.amount}/{selectedKeyTopic.amount})
            </chakra.span>
          </HStack>
        )}
      </CardContent>
    </Card>
  )
})

export const RepeatCallsWidgetCard: React.FC<{
  isRepeatCallFilterApplied: boolean
  keyTopicsSessionUid: string | undefined
  data: RepeatCallsWidgetReadySelector
}> = ({ keyTopicsSessionUid, data, isRepeatCallFilterApplied }) => {
  const [openKeyTopicsDrawer] = useModal(KeyTopicsDrawer)

  const [selectedKeyTopicId, setSelectedKeyTopicId] = useState<
    string | undefined
  >(data.topKeyTopicClusters[0]?.id)

  useEffect(() => {
    if (
      data &&
      data.topKeyTopicClusters.length > 0 &&
      selectedKeyTopicId === undefined
    ) {
      setSelectedKeyTopicId(data.topKeyTopicClusters[0].id)
    }
  }, [data, selectedKeyTopicId])

  const selectedKeyTopic = useMemo(
    () =>
      data.displayedKeyTopicClusters.find(
        (topic) => topic.id === selectedKeyTopicId,
      ),
    [data, selectedKeyTopicId],
  )

  if (
    data.displayedKeyTopicClusters.length === 0 &&
    !isRepeatCallFilterApplied
  ) {
    return (
      <NoRepeatCallsWidgetCard
        heading={t`No repeat calls`}
        body={t`With the current selection there isn’t any conversations that are marked as repeat calls.`}
      />
    )
  }

  if (isRepeatCallFilterApplied) {
    return (
      <NoRepeatCallsWidgetCard
        heading={t`No insights available`}
        body={t`As all conversations in the current filter selection are repeat calls. Remove 'Repeat call' from the filter to view insights.`}
      />
    )
  }

  return (
    <Card mt={2}>
      <CardContent h="100%">
        <Text fontSize="lg" fontWeight="medium">
          <Trans>Topics with highest/lowest share of repeat calls</Trans>
        </Text>
        <HStack alignItems="stretch" gap={4} mt={2} w="100%" h="100%">
          <VStack alignItems="stretch" gap={3}>
            <KeyTopicsList
              topItems={data.topKeyTopicClusters}
              bottomItems={data.bottomKeyTopicClusters}
              selectedKeyTopicId={selectedKeyTopicId}
              setSelectedKeyTopicId={setSelectedKeyTopicId}
            />
          </VStack>
          <Box
            backgroundColor="gray.200"
            position="relative"
            w="1px"
            top="0"
            bottom="0"
          />
          <VStack w="100%" alignItems="stretch">
            <Flex gap="8" justifyContent="space-between" w="100%">
              {selectedKeyTopic && (
                <Flex flexDir="column" w="200px" flexGrow="1">
                  <Text
                    fontSize="md"
                    fontWeight="medium"
                    textColor="gray.800"
                    textTransform="uppercase"
                  >
                    {selectedKeyTopic.label}
                  </Text>
                  <Text
                    fontSize="xs"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    textColor="gray.500"
                    w="100%"
                  >
                    {selectedKeyTopic.keyTopics
                      ?.slice(
                        1,
                        5,
                      ) /* Skip first topic and stop at the fifth, as it's in label */
                      .map((topic) => topic.name)
                      .join(', ')}
                    {selectedKeyTopic.keyTopics.length > 5 && (
                      <Trans>, etc.</Trans>
                    )}
                  </Text>
                </Flex>
              )}
              <PlaySnippetsButton
                onClick={(e) => {
                  e.stopPropagation()
                  if (!(selectedKeyTopic && keyTopicsSessionUid)) return
                  analytics.event('repeat_calls_drawer_opened')
                  openKeyTopicsDrawer({
                    topicId: selectedKeyTopic.id,
                    sessionUid: keyTopicsSessionUid,
                    referenceBenchmark: data.benchmark,
                    clusterName: selectedKeyTopic.label,
                    topics: selectedKeyTopic.keyTopics,
                    onlyRepeatCalls: true,
                    overridenPercentages: {
                      percentage: selectedKeyTopic.repeatCallsShare * 100,
                    },
                  })
                }}
                label={t`Discover`}
              />
            </Flex>
            <Grid templateColumns="1fr 2fr" gap={2} textAlign="left">
              <GridItem>
                {selectedKeyTopic?.repeatCalls && (
                  <SelectedTopicInfoCard selectedKeyTopic={selectedKeyTopic} />
                )}
              </GridItem>
              <GridItem>
                <Card
                  h="100%"
                  border="1px solid"
                  borderColor="gray.200"
                  borderRadius="md"
                  boxShadow="none"
                  p={0}
                >
                  <CardContent p={0} h="100%">
                    <Grid h="100%" templateColumns="1fr 1px 1fr">
                      <GridItem py={4} pl={4} pr={2}>
                        <Text fontSize="xs">
                          <Trans>Avg. duration per repeat call</Trans>
                        </Text>
                        {selectedKeyTopic?.repeatCalls?.avgDuration && (
                          <Text fontSize="2xl" fontWeight="medium">
                            {formatSecondsToShortMS(
                              selectedKeyTopic?.repeatCalls?.avgDuration,
                            )}
                          </Text>
                        )}
                      </GridItem>
                      <GridItem h="100%" w="1px" bg="gray.200" />
                      <GridItem py={3} pl={2} pr={4}>
                        <Text fontSize="xs">
                          <Trans>Time spent on repeat calls</Trans>
                        </Text>
                        {selectedKeyTopic?.repeatCalls?.sumDuration && (
                          <Text fontSize="2xl" fontWeight="medium">
                            {formatSecondsToShortHM(
                              selectedKeyTopic?.repeatCalls?.sumDuration,
                            )}
                          </Text>
                        )}
                      </GridItem>
                    </Grid>
                  </CardContent>
                </Card>
              </GridItem>
            </Grid>
            <AnimateChangeInHeight>
              <Box position="relative">
                <SharedDigest
                  keyTopicsSessionUid={keyTopicsSessionUid}
                  keyTopicClusterId={selectedKeyTopicId}
                  keyTopicClusterLabel={t`What can potentially reduce repeat calls about ${selectedKeyTopic?.label} in the future?`}
                  widgetType="repeatCalls"
                />
              </Box>
            </AnimateChangeInHeight>
          </VStack>
        </HStack>
      </CardContent>
    </Card>
  )
}
