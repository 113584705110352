import { SentimentScore, SentimentSpeaker } from '@capturi/api-sentiment'
import { useOrganization } from '@capturi/stores'
import { RadioButton, RadioButtonGroup } from '@capturi/ui-components'
import { MultiSelectFooter } from '@capturi/ui-select'
import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { useEffectOnce } from 'react-use'

import { FilterCriteriaComponentBaseProps } from '../../components/PhoneFilter/components/PhoneSegmentBuilder'
import { SentimentState } from '../../types'
import { scoreToString, speakerToString } from './utils'

const scoreOptions: SentimentScore[] = ['High', 'Medium', 'Low']

const speakerOptions: SentimentSpeaker[] = ['Other', 'User']

type SentimentSelectProps = FilterCriteriaComponentBaseProps<SentimentState>

export function SentimentSelect({
  value: sentiment,
  setValue,
  onClose,
}: SentimentSelectProps): React.ReactElement {
  const { organizationType } = useOrganization()

  const score = sentiment?.score ?? 'Medium'
  const speaker = sentiment?.speaker ?? 'User'

  const onSubmit = (state: Partial<SentimentState>): void => {
    setValue?.({
      score: state.score ?? score,
      speaker: state.speaker ?? speaker,
    })
  }

  useEffectOnce(() => {
    /**
     * If there was no sentiment state initially then
     * trigger a state update setting the default values
     */
    if (sentiment == null) {
      onSubmit({ score, speaker })
    }
  })

  return (
    <>
      <Stack spacing={4} py={2} px={4}>
        <FormControl>
          <FormLabel htmlFor="sentiment-speaker">
            <Trans>Speaker</Trans>
          </FormLabel>
          <RadioButtonGroup
            name="sentiment-speaker"
            value={speaker.toString()}
            onChange={(val) =>
              onSubmit({
                speaker: val as SentimentSpeaker,
              })
            }
          >
            {speakerOptions.map((o) => (
              <RadioButton
                key={o}
                value={o.toString()}
                id={`sentiment-speaker-${o.toString()}`}
              >
                {speakerToString(organizationType, o)}
              </RadioButton>
            ))}
          </RadioButtonGroup>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="sentiment-score">
            <Trans>Score</Trans>
          </FormLabel>
          <RadioGroup
            id="sentiment-score"
            value={score.toString()}
            onChange={(val) =>
              onSubmit({
                score: val as SentimentScore,
              })
            }
          >
            <Stack>
              {scoreOptions.map((s) => (
                <Radio
                  key={s}
                  cursor="pointer"
                  value={s.toString()}
                  id={`sentiment-score-${s.toString()}`}
                >
                  {scoreToString(s)}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </FormControl>
      </Stack>
      <MultiSelectFooter onSubmit={onClose} submitText={t`OK`} />
    </>
  )
}
