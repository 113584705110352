import { type TrackerFolder } from '@capturi/api-trackers'
import type { PreviewAccessResponse } from '@capturi/core'
import { PreviewAccessWarningText, ValidationErrorText } from '@capturi/sharing'
import { PermissionPresetState } from '@capturi/sharing/src/components/PermissionPresetState'
import { Box, Icon, Text, useDisclosure } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { PermissionDialog } from 'pages/analytics/shared/components/PermissionDialog'
import type { FC } from 'react'
import type { UseFormSetValue } from 'react-hook-form'
import { MdShare } from 'react-icons/md'
import type { TrackerFormModel } from '../types'

type Props = {
  setValue: UseFormSetValue<TrackerFormModel>
  formValues: TrackerFormModel
  previewAccessResponse: PreviewAccessResponse | undefined
  validationError: string | undefined
  folderData: TrackerFolder | undefined
}

function getPreviewAccessWarningText(
  previewAccessResponse: PreviewAccessResponse | undefined,
): string | undefined {
  switch (previewAccessResponse) {
    case 'NoAccess':
      return t`*You will no longer be able to view the tracker when you apply`
    case 'Editable':
      return undefined
    case 'Viewable':
      return t`*You will no longer be able to edit the tracker when you apply because you set your own permission to “viewer” `
  }
}

const SharingAndEditRights: FC<Props> = ({
  setValue,
  formValues,
  previewAccessResponse,
  validationError,
  folderData,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box fontWeight="500" display="flex" alignItems="center">
        <Icon as={MdShare} />
        <Text mx={2}>
          <Trans>Sharing and edit rights</Trans>
        </Text>
      </Box>
      <PermissionPresetState
        permissionPreset={formValues.permissionPreset}
        localizedEntityName={t`trackers`}
        onOpen={onOpen}
        acl={formValues.acl}
      />
      <PreviewAccessWarningText
        warningText={getPreviewAccessWarningText(previewAccessResponse)}
      />
      <ValidationErrorText validationError={validationError} />
      {isOpen && (
        <PermissionDialog
          initialPermissionPreset={formValues.permissionPreset}
          initialAcl={formValues.acl ?? null}
          folderPermissionPreset={folderData?.permissionPreset}
          permissionPresetUserUid={formValues.permissionPresetUserUid}
          folderAcl={folderData?.acl}
          header={t`View & edit`}
          isOpen={isOpen}
          onClose={onClose}
          onSave={(model) => {
            setValue('acl', model.acl)
            setValue('permissionPreset', model.permissionPreset)
            setValue('permissionPresetUserUid', model.permissionPresetUserUid)
          }}
        />
      )}
      )
    </>
  )
}

export default SharingAndEditRights
