export const basePath = '/conversations/playback'
export const baseCasePath = '/conversations/emails'

export const conversationPageViews = {
  overview: 'overview',
  comments: 'comments',
  trackers: 'trackers',
  history: 'history',
  scoring: 'scoring',
  sentiment: 'sentiment',
  statistics: 'statistics',
  search: 'search',
} as const

export const casePageViews = {
  comments: 'comments',
  trackers: 'trackers',
  history: 'history',
  scoring: 'scoring',
  sentiment: 'sentiment',
  statistics: 'statistics',
  search: 'search',
} as const

export const toConversationPageView = (
  path: string | undefined,
): ConversationPageView | undefined => {
  return path
    ? conversationPageViews[path as keyof typeof conversationPageViews]
    : undefined
}
export type ConversationPageView =
  (typeof conversationPageViews)[keyof typeof conversationPageViews]

export type CasePageView =
  (typeof conversationPageViews)[keyof typeof casePageViews]

const routes = {
  root: basePath,
  cases: baseCasePath,
  list: (): string => basePath,
  conversation: (
    uid: string,
    subPath?: ConversationPageView,
    searchParams?: string,
  ): string => {
    const root = `${basePath}/${uid}`
    if (subPath === undefined || subPath === 'overview') {
      return `${root}${searchParams ? searchParams : ''}`
    }
    return `${root}/${subPath}${searchParams ? searchParams : ''}`
  },
  caseList: (): string => basePath,
  case: (uid: string, subPath?: CasePageView): string => {
    const root = `${baseCasePath}/${uid}`
    if (subPath === undefined || subPath === 'trackers') {
      return root
    }
    return `${root}/${subPath}`
  },
}

export default routes
