import analytics from '@capturi/analytics'
import { useTheme } from '@capturi/ui-theme'
import { Box } from '@chakra-ui/react'
import { scaleSymlog } from 'd3-scale'
import { FC, useCallback, useMemo } from 'react'
import { useMeasure } from 'react-use'

import ReactD3Cloud from './ReactD3Cloud'

export type WordCloudItem = {
  name: string
  value: number
}

type WordCloudProps = {
  items: WordCloudItem[]
  fill: (name: string) => string
  selectedItemName?: string
  handleClick?: (item: WordCloudItem) => void
}

const normalize = (val: number, min: number, max: number): number => {
  if (max <= min) return 0.5
  return (val - min) / (max - min)
}
const rotate = () => 0
const random = () => 0.5
const fontSize = (word: { value: number; text: string }) => {
  const sizeScale = scaleSymlog().domain([0, 1]).range([1, 12])
  return sizeScale(word.value)
}

export const WordCloud: FC<WordCloudProps> = ({
  items,
  fill,
  selectedItemName,
  handleClick,
}) => {
  const [measureRef, { width, height }] = useMeasure<HTMLDivElement>()

  const theme = useTheme()

  const normalizedItems = useMemo(() => {
    const absItems = items.map((item) => ({
      ...item,
      value: Math.abs(item.value),
    }))
    const minTrend = Math.min(...absItems.map((item) => item.value)) * 0.7
    const maxTrend = Math.max(...absItems.map((item) => item.value))
    return absItems.map((item) => ({
      text: item.name,
      value: normalize(item.value, minTrend, maxTrend),
    }))
  }, [items])

  const fillCallback = useCallback(
    (d: { text: string }) => fill(d.text),
    [fill],
  )
  const className = useCallback(
    (d: { text: string }) => {
      if (d.text === selectedItemName) {
        return 'selected'
      }
      return selectedItemName ? 'dimmed' : ''
    },
    [selectedItemName],
  )
  const onWordClick = useCallback(
    (_: unknown, x: { text: string; value: number }) => {
      handleClick?.({
        name: x.text,
        value: x.value,
      })
      analytics.event('wordcloud_selected')
    },
    [handleClick],
  )

  return (
    <Box
      ref={measureRef}
      w="100%"
      h="100%"
      display={normalizedItems.length > 0 ? 'block' : 'none'}
    >
      <ReactD3Cloud
        width={width}
        height={height}
        data={normalizedItems}
        font={theme.fonts.body}
        fontSize={fontSize}
        spiral="archimedean"
        rotate={rotate}
        padding={2}
        random={random}
        fill={fillCallback}
        className={className}
        onWordClick={onWordClick}
      />
    </Box>
  )
}
