import { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  InfiniteData,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

import {
  ConversationResponseModel,
  type ConversationsResponseModel,
} from '../models'

const conversationUrl = (uid: string): string =>
  `conversations/${uid}?api-version=3.3`

export const useConversation = (
  uid: string,
  refetchInterval?: number,
): UseQueryResult<ConversationResponseModel, ResponseError> =>
  useQuery({
    queryKey: ['conversations', uid],
    queryFn: () => request.get<ConversationResponseModel>(conversationUrl(uid)),
    refetchOnMount: false, //Not sure why this is needed, it goes into a loop without it
    refetchInterval: refetchInterval,
    staleTime: 1000 * 60 * 10,
  })

export const useDeleteConversation = (
  conversationUid: string,
): UseMutationResult<void, ResponseError, void, ResponseError> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: () => request.delete(conversationUrl(conversationUid)),
    onSuccess: () => {
      queryClient.setQueriesData<InfiniteData<ConversationsResponseModel>>(
        { queryKey: ['conversations', 'list'] },
        (cacheData) => {
          if (!cacheData) return cacheData
          return {
            ...cacheData,
            pages: cacheData.pages.map((p) => ({
              continuationToken: p.continuationToken,
              conversations: p.conversations.filter(
                (d) => d.uid !== conversationUid,
              ),
            })),
          }
        },
      )

      queryClient.setQueryData(['conversations', conversationUid], null)
    },
  })
}

export const useUpdateIsReviewed = (
  conversationUid: string,
): UseMutationResult<
  ConversationResponseModel,
  ResponseError,
  { isReviewed: boolean }
> => {
  const queryClient = useQueryClient()
  const queryKey = ['conversations', conversationUid]
  return useMutation({
    mutationFn: (data) =>
      request.post(
        `conversations/${conversationUid}/qa-reviewed?api-version=3.3`,
        {
          json: data,
        },
      ),

    onMutate: async ({ isReviewed }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: queryKey })

      // Snapshot the previous value
      const previousData =
        queryClient.getQueryData<ConversationResponseModel>(queryKey)

      // Optimistically update to the new value
      queryClient.setQueryData<ConversationResponseModel>(queryKey, (old) => {
        if (!old) return old
        return {
          ...old,
          qaIsReviewed: isReviewed,
        }
      })
      //Update the sidebare/conversation list
      queryClient.setQueriesData<InfiniteData<ConversationsResponseModel>>(
        { queryKey: ['conversations', 'list'] },
        (cacheData) => {
          if (!cacheData) return cacheData
          return {
            ...cacheData,
            pages: cacheData.pages.map((p) => {
              return {
                continuationToken: p.continuationToken,
                conversations: p.conversations.map((d) =>
                  d.uid === conversationUid
                    ? {
                        ...d,
                        qaIsReviewed: isReviewed,
                      }
                    : d,
                ),
              }
            }),
          }
        },
      )
      return { previousData }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_err, _variables, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(queryKey, context.previousData)
        //Update the sidebare/conversation list
        queryClient.setQueriesData<InfiniteData<ConversationsResponseModel>>(
          { queryKey: ['conversations', 'list'] },
          (cacheData) => {
            if (!cacheData) return cacheData
            return {
              ...cacheData,
              pages: cacheData.pages.map((p) => ({
                continuationToken: p.continuationToken,
                conversations: p.conversations.map((d) =>
                  d.uid === conversationUid
                    ? {
                        ...d,
                        qaIsReviewed: !!context.previousData?.qaIsReviewed,
                      }
                    : d,
                ),
              })),
            }
          },
        )
      }
    },
  })
}

//For legacy reasons
export const useMutateConversation = (
  conversationUid: string,
): {
  mutate: (data: Partial<ConversationResponseModel>) => void
} => {
  const queryClient = useQueryClient()

  const mutate = (data: Partial<ConversationResponseModel>): void => {
    queryClient.setQueryData<ConversationResponseModel>(
      ['conversations', conversationUid],
      (oldData) => {
        if (!oldData) return oldData

        return { ...oldData, ...data }
      },
    )
  }

  return { mutate }
}
