import analytics from '@capturi/analytics'
import { AudioControls } from '@capturi/audio'
import { useModal } from '@capturi/use-modal'
import { t } from '@lingui/macro'
import CreateCommentModal, {
  CommentModalOptions,
} from 'components/ConversationDetails/Comments/CreateCommentModal'
import { useSnippetCRUD as useAudioSnippetCRUD } from 'features/library'
import { CreateSnippetDialogOptions } from 'features/library/components/CreateSnippetDialog'
import React from 'react'
import { MdAddToPhotos, MdComment } from 'react-icons/md'

function OptionalAudioPlayerActions(props: {
  duration: number
  getTime: () => number
  isPlaying: boolean
  toggle: () => void
  conversationUid: string
  getCreateSnippetProps?: () => Partial<CreateSnippetDialogOptions>
  getCreateCommentProps?: () => Partial<CommentModalOptions>
}): React.ReactElement {
  const audioSnippetActions = useAudioSnippetCRUD()
  const [openCommentsModal] = useModal(CreateCommentModal)
  const { duration, isPlaying, toggle, getTime, conversationUid } = props

  const handleOpenCreateSnippet = (): void => {
    analytics.event('optionalAudioPlayerActions_OpenCreateSnippetDialog')
    if (isPlaying) {
      toggle()
    }
    // getCreateSnippetProps (initial title) needs to be passed here. Function is run below which opens the modal.
    audioSnippetActions.createSnippet(
      conversationUid,
      getTime(),
      duration,
      props.getCreateSnippetProps?.().initialTitle,
    )
  }

  const handleOpenCreateComment = (): void => {
    analytics.event('optionalAudioPlayerActions_OpenCreateCommentDialog')

    if (isPlaying) {
      toggle()
    }

    const additionalProps = props.getCreateCommentProps?.() ?? {}
    openCommentsModal({
      conversationUid,
      initialTime: getTime(),
      ...additionalProps,
    })
  }

  return (
    <AudioControls.ButtonGroup spacing={1}>
      <AudioControls.IconButton
        title={t`Add a comment`}
        onClick={handleOpenCreateComment}
      >
        <MdComment />
      </AudioControls.IconButton>
      <AudioControls.IconButton
        title={t`Add to library`}
        onClick={handleOpenCreateSnippet}
      >
        <MdAddToPhotos />
      </AudioControls.IconButton>
    </AudioControls.ButtonGroup>
  )
}

export default OptionalAudioPlayerActions
