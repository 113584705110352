import { Range } from '@capturi/api-filters'
import { plural, t } from '@lingui/macro'
import isNumber from 'lodash/isNumber'

export function areEqual(a: Range, b: Range): boolean {
  const minEqual = a.min === b.min || (a.min == null && b.min == null)
  const maxEqual = a.max === b.max || (a.max == null && b.max == null)
  return minEqual && maxEqual
}

export const formatSecondsToShortMS = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60

  const mText = `${minutes}${t`m`}`
  const sText = `${remainingSeconds.toFixed(0)}${t`s`}`

  if (seconds < 60) {
    return sText
  }
  if (seconds < 1) {
    return `0${t`s`}`
  }
  return `${mText} ${sText}`
}

export const formatSecondsToShortHM = (seconds: number) => {
  const hours = Math.floor(seconds / (60 * 60))
  const remainingMinutes = Math.floor((seconds % (60 * 60)) / 60)

  const hText = `${hours}${t`h`}`
  const mText = `${remainingMinutes}${t`m`}`

  if (seconds < 60 * 60) {
    return mText
  }
  if (seconds < 60) {
    return `0${t`m`}`
  }
  return `${hText} ${mText}`
}

export const formatSecondsToLongDHMS = (seconds: number): string => {
  const d = Math.floor(seconds / (24 * 3600))
  const h = Math.floor((seconds % (24 * 3600)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)

  const dText = `${d} ${plural(d, {
    one: 'day',
    other: 'days',
  })}`

  const hText = `${h} ${plural(h, {
    one: 'hour',
    other: 'hours',
  })}`

  const mText = `${m} ${plural(m, {
    one: 'minute',
    other: 'minutes',
  })}`

  const sText = `${s} ${plural(s, {
    one: 'second',
    other: 'seconds',
  })}`

  return [d > 0 && dText, h > 0 && hText, m > 0 && mText, s > 0 && sText]
    .filter(Boolean)
    .join(` ${t`and`} `)
}

export const formatSecondsToLongHMS = (seconds: number): string => {
  const h = Math.floor(seconds / 3600)
  const m = Math.floor(seconds / 60) % 60
  const s = Math.floor(seconds) % 60

  const hText = `${h} ${plural(h, {
    one: 'hour',
    other: 'hours',
  })}`

  const mText = `${m} ${plural(m, {
    one: 'minute',
    other: 'minutes',
  })}`

  const sText = `${s} ${plural(s, {
    one: 'second',
    other: 'seconds',
  })}`

  return [h > 0 && hText, m > 0 && mText, s > 0 && sText]
    .filter(Boolean)
    .join(` ${t`and`} `)
}

export function formatDuration(duration: Range): string {
  const { min, max } = duration
  if (isNumber(min) && isNumber(max)) {
    return `${formatSecondsToLongHMS(min)} - ${formatSecondsToLongHMS(max)}`
  }
  if (isNumber(max)) {
    return t`Maximum ${formatSecondsToLongHMS(max)}`
  }
  if (isNumber(min)) {
    return t`Minimum ${formatSecondsToLongHMS(min)}`
  }
  return ''
}
