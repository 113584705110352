import { AdversusStatus } from '@capturi/api-conversations'
import {
  PhoneFilterValues,
  Range,
  RepeatCall,
  ScoreState,
} from '@capturi/api-filters'
import {
  OrganizationMetadataSettings,
  useOrganizationSettings,
} from '@capturi/api-organization'
import { CurrentUser, Role } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { useOrganization } from '@capturi/stores'
import { select, t } from '@lingui/macro'
import merge from 'lodash/merge'
import React, { createContext, useContext, useMemo } from 'react'
import { FaIdCard } from 'react-icons/fa'
import {
  MdAssistant,
  MdCall,
  MdCallSplit,
  MdCheckCircle,
  MdDataUsage,
  MdFiberSmartRecord,
  MdLabel,
  MdLocationDisabled,
  MdMyLocation,
  MdOutlineCircle,
  MdPeople,
  MdPerson,
  MdSentimentSatisfied,
  MdSyncProblem,
  MdTimer,
  MdVerifiedUser,
} from 'react-icons/md'
import { useLatest } from 'react-use'

import { FilterValueLabel } from './components/FilterValueLabel'
import {
  FilterDefinition,
  FilterDefinitions,
} from './components/PhoneFilter/components/PhoneSegmentBuilder'
import {
  CustomPropFilterComponent,
  CustomPropFilterProps,
  CustomPropFilterValueLabel,
  CustomerInput,
  CustomerValueLabel,
  DurationSelect,
  DurationValueLabel,
  ExternalIdentityInput,
  ExternalIdentityValueLabel,
  KeyTopicSelect,
  KeyTopicSelectProps,
  KeyTopicValueLabel,
  LabelSelect,
  LabelSelectProps,
  LabelsValueLabel,
  NotTrackerSelect,
  QualityAssurance,
  QualityAssuranceProps,
  QualityAssuranceValueLabel,
  RecordedTracksProps,
  RepeatCallProps,
  RepeatCalls,
  RepeatCallsFilterValueLabel,
  ScoreSelect,
  ScoreTooltipWrapper,
  ScoreValueLabel,
  SentimentSelect,
  SentimentValueLabel,
  Speakers,
  SpeakersValueLabel,
  StatusSelect,
  StatusValueLabel,
  SubjectSelect,
  SubjectSelectProps,
  SubjectValueLabel,
  TeamSelect,
  TeamSelectProps,
  TeamValueLabel,
  TrackerSelect,
  TrackerSelectProps,
  TrackersValueLabel,
  UserSelect,
  UserSelectProps,
  UsersValueLabel,
} from './filters'
import { TrackerTooltipWrapper } from './filters/tracker/TrackerTooltipWrapper'
import { DEFAULT_STATE } from './state/segment-state'
import { BaseTrackerState, SentimentState, TrackerState } from './types'
import { getCustomPropIcon } from './utils/customProps'

type FilterValueKey = keyof PhoneFilterValues

// TODO: look into how these options can be inferred so this is not necessary
export type FilterComponentOptions = {
  trackers: TrackerSelectProps['options']
  notTrackers: TrackerSelectProps['options']
  teamUids: TeamSelectProps['options']
  userUids: UserSelectProps['options']
  labels: LabelSelectProps['options']
  subjects: SubjectSelectProps['options']
  keyTopics: KeyTopicSelectProps['options']
  repeatCalls: RepeatCallProps['options']
  speakers: RecordedTracksProps['options']
  qaIsReviewed: QualityAssuranceProps['options']
  hasNewBadge?: boolean

  // biome-ignore lint/suspicious/noExplicitAny: legacy
  customProp1: CustomPropFilterProps<any>['options']
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  customProp2: CustomPropFilterProps<any>['options']
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  customProp3: CustomPropFilterProps<any>['options']
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  customProp4: CustomPropFilterProps<any>['options']
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  customProp5: CustomPropFilterProps<any>['options']
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  customProp6: CustomPropFilterProps<any>['options']
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  customProp7: CustomPropFilterProps<any>['options']
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  customProp8: CustomPropFilterProps<any>['options']
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  customProp9: CustomPropFilterProps<any>['options']
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  customProp10: CustomPropFilterProps<any>['options']
}

type FilterDefinitionOverrides = Partial<{
  [key in FilterValueKey]: Partial<FilterDefinition>
}>

export function getFilterDefinitions(
  inactiveKeys: Set<FilterValueKey> | undefined = new Set(),
  overrides: FilterDefinitionOverrides = {},
  orgType?: string,
): FilterDefinitions<FilterValueKey> {
  let orgBasedValue: string

  if (orgType) {
    orgBasedValue = select(orgType, {
      public: 'Citizens',
      other: 'Customers',
    })
  } else {
    orgBasedValue = 'Customers'
  }
  function createCustomNumberPropPlaceholderDefinition(
    index: number,
  ): FilterDefinition {
    return {
      name: `Custom Number Prop ${index}`,
      icon: MdOutlineCircle,
      renderText: (value) => (
        <FilterValueLabel name={`Custom Number Prop ${index}`} value={value} />
      ),
      Component: () => null,
      isAvailable: () => false,
      sortGroup: 'custom-props',
    } as FilterDefinition<Range>
  }

  function createCustomPropPlaceholderDefinition(
    index: number,
  ): FilterDefinition {
    return {
      name: `Custom Prop ${index}`,
      icon: MdOutlineCircle,
      renderText: (value) => (
        <FilterValueLabel name={`Custom Prop ${index}`} value={value} />
      ),
      Component: () => <span />,
      isAvailable: () => false,
      sortGroup: 'custom-props',
    } as FilterDefinition<string[]>
  }

  const filterMap = {
    teamUids: {
      name: t`Team`,
      icon: MdPeople,
      renderText: (values) => <TeamValueLabel uids={values} />,
      Component: TeamSelect,
    } as FilterDefinition<string[], TeamSelectProps>,
    userUids: {
      name: t`Employee`,
      icon: MdPerson,
      renderText: (values) => <UsersValueLabel uids={values} />,
      Component: UserSelect,
    } as FilterDefinition<string[], UserSelectProps>,
    subjects: {
      name: t`Queue / campaign`,
      icon: MdCallSplit,
      renderText: (values) => <SubjectValueLabel value={values} />,
      Component: SubjectSelect,
    } as FilterDefinition<string[], SubjectSelectProps>,
    repeatCalls: {
      name: t`Repeat call`,
      icon: MdSyncProblem,
      renderText: (values: RepeatCall) => (
        <RepeatCallsFilterValueLabel value={values ?? {}} />
      ),
      allowMultiple: true,
      Component: RepeatCalls,
      popoverWidth: 505,
    } as FilterDefinition<RepeatCall>,
    speakers: {
      name: t`Speakers`,
      icon: MdFiberSmartRecord,
      renderText: (values) => <SpeakersValueLabel value={values} />,
      Component: Speakers,
    } as FilterDefinition<number[], RecordedTracksProps>,
    status: {
      name: t`Status`,
      icon: MdVerifiedUser,
      renderText: (value: AdversusStatus) => <StatusValueLabel value={value} />,
      Component: StatusSelect,
    } as FilterDefinition<string>,
    labels: {
      name: t`Label`,
      icon: MdLabel,
      renderText: (value) => <LabelsValueLabel value={value} />,
      Component: LabelSelect,
    } as FilterDefinition<string[], LabelSelectProps>,
    trackers: {
      name: t`Tracker detected`,
      icon: MdMyLocation,
      renderText: (state) => <TrackersValueLabel state={state} />,
      Component: TrackerSelect,
      allowMultiple: true,
      hasValue: (state) => (state?.uids ?? []).length > 0,
      filterCriteriaButtonWrapper: (props) => (
        <TrackerTooltipWrapper state={props.value}>
          {props.children}
        </TrackerTooltipWrapper>
      ),
    } as FilterDefinition<TrackerState, TrackerSelectProps>,
    notTrackers: {
      name: t`Tracker not detected`,
      icon: MdLocationDisabled,
      renderText: (state) => <TrackersValueLabel state={state} exclusion />,
      Component: NotTrackerSelect,
      allowMultiple: true,
      hasValue: (state) => (state?.uids ?? []).length > 0,
      filterCriteriaButtonWrapper: (props) => (
        <TrackerTooltipWrapper state={props.value}>
          {props.children}
        </TrackerTooltipWrapper>
      ),
    } as FilterDefinition<BaseTrackerState, TrackerSelectProps>,
    duration: {
      name: t`Duration`,
      icon: MdTimer,
      renderText: (value) => <DurationValueLabel value={value} />,
      Component: DurationSelect,
    } as FilterDefinition<Range>,
    customers: {
      name: orgBasedValue,
      icon: MdCall,
      renderText: (value) => <CustomerValueLabel value={value} />,
      Component: CustomerInput,
    } as FilterDefinition<string[]>,
    sentiment: {
      name: t`Sentiment`,
      icon: MdSentimentSatisfied,
      renderText: (value) => <SentimentValueLabel value={value} />,
      Component: SentimentSelect,
    } as FilterDefinition<SentimentState>,
    externalIdentity: {
      name: t`External id`,
      icon: FaIdCard,
      renderText: (value: string) => (
        <ExternalIdentityValueLabel value={value} />
      ),
      Component: ExternalIdentityInput,
    } as FilterDefinition<string>,
    qaIsReviewed: {
      name: t`Reviewed`,
      icon: MdCheckCircle,
      renderText: (value) => <QualityAssuranceValueLabel value={value} />,
      Component: QualityAssurance,
    } as FilterDefinition<boolean>,
    scores: {
      name: t`Score`,
      icon: MdAssistant,
      renderText: (value) => <ScoreValueLabel value={value || []} />,
      Component: ScoreSelect,
      hasValue: (state) => (state ?? []).length > 0,
      filterCriteriaButtonWrapper: (props) => {
        //Fix array vs non array stuff with state.
        return (
          <ScoreTooltipWrapper state={props?.value || []}>
            {props.children}
          </ScoreTooltipWrapper>
        )
      },
    } as FilterDefinition<ScoreState[]>,
    keyTopics: {
      name: t`Key topics`,
      icon: MdDataUsage,
      hasNewBadge: true,
      renderText: (value) => <KeyTopicValueLabel value={value || []} />,
      Component: KeyTopicSelect,
      hasValue: (state) => (state ?? []).length > 0,
    } as FilterDefinition<string[]>,
    customNumberProp1: createCustomNumberPropPlaceholderDefinition(1),
    customNumberProp2: createCustomNumberPropPlaceholderDefinition(2),
    customNumberProp3: createCustomNumberPropPlaceholderDefinition(3),
    customNumberProp4: createCustomNumberPropPlaceholderDefinition(4),
    customNumberProp5: createCustomNumberPropPlaceholderDefinition(5),
    customNumberProp6: createCustomNumberPropPlaceholderDefinition(6),
    customNumberProp7: createCustomNumberPropPlaceholderDefinition(7),
    customNumberProp8: createCustomNumberPropPlaceholderDefinition(8),
    customNumberProp9: createCustomNumberPropPlaceholderDefinition(9),
    customNumberProp10: createCustomNumberPropPlaceholderDefinition(10),
    customProp1: createCustomPropPlaceholderDefinition(1),
    customProp2: createCustomPropPlaceholderDefinition(2),
    customProp3: createCustomPropPlaceholderDefinition(3),
    customProp4: createCustomPropPlaceholderDefinition(4),
    customProp5: createCustomPropPlaceholderDefinition(5),
    customProp6: createCustomPropPlaceholderDefinition(6),
    customProp7: createCustomPropPlaceholderDefinition(7),
    customProp8: createCustomPropPlaceholderDefinition(8),
    customProp9: createCustomPropPlaceholderDefinition(9),
    customProp10: createCustomPropPlaceholderDefinition(10),
  }

  return Object.entries(filterMap).reduce<
    Map<FilterValueKey, FilterDefinition>
  >((memo, [key, baseDefinition]) => {
    const filterName = key as FilterValueKey
    const definition = {
      ...baseDefinition,
      ...(overrides[filterName] ?? {}),
    }
    definition.inactive = inactiveKeys.has(filterName)
    memo.set(filterName, definition)
    return memo
  }, new Map())
}
export function useFilterDefinitions(
  user: CurrentUser,
  overrides?: FilterDefinitionOverrides,
  options?: {
    enableCustomProperties?: boolean
  },
): FilterDefinitions<FilterValueKey> {
  const { inactiveKeys } = useSegmentFilterKeys()
  const { organizationType } = useOrganization()
  const featureFlags = useFeatureFlags()
  const overridesRef = useLatest(overrides)

  const userRole = user.role ?? Role.user

  const overridesFromCustomPropSettings = useCustomPropDefinitions(
    user.language,
    options?.enableCustomProperties,
  )

  return useMemo(() => {
    const overridesFromFeatureFlagsAndUserRole: FilterDefinitionOverrides = {
      teamUids: {
        isAvailable: () =>
          [Role.administrator, Role.teamlead].includes(userRole),
      },
      userUids: {
        isAvailable: () =>
          [Role.administrator, Role.teamlead].includes(userRole),
      },
      status: {
        isAvailable: () => featureFlags.showStatus === true,
      },
      externalIdentity: {
        isAvailable: () => featureFlags.showExternalId === true,
      },
      speakers: {
        isAvailable: () => featureFlags.useSpeakersFilter === true,
      },
      keyTopics: {
        isAvailable: () => featureFlags.aiFeatures === true,
      },
      qaIsReviewed: {
        isAvailable: () => user.permissions.qualityAssurance === true,
      },
    }
    const mergedOverrides = merge(
      {},
      overridesFromFeatureFlagsAndUserRole,
      overridesFromCustomPropSettings,
      overridesRef.current,
    )

    const inactiveOverrides: (keyof PhoneFilterValues)[] = []
    Object.entries(mergedOverrides).forEach(([key, def]) => {
      if (def.inactive !== undefined) {
        inactiveOverrides.push(key as keyof PhoneFilterValues)
      }
    })
    return getFilterDefinitions(
      new Set([...inactiveKeys, ...inactiveOverrides]),
      mergedOverrides,
      organizationType,
    )
  }, [
    overridesFromCustomPropSettings,
    overridesRef,
    inactiveKeys,
    organizationType,
    userRole,
    featureFlags.showStatus,
    featureFlags.showExternalId,
    featureFlags.aiFeatures,
    featureFlags.useSpeakersFilter,
    user.permissions.qualityAssurance,
  ])
}

function useCustomPropDefinitions(
  locale: string,
  enabled = true,
): FilterDefinitionOverrides {
  const { data } = useOrganizationSettings()

  return React.useMemo(() => {
    if (!enabled) return {}
    if (!data) return {}

    return Object.entries(data.metadata).reduce<FilterDefinitionOverrides>(
      (memo, [_filterKey, settings]) => {
        const filterKey = _filterKey as keyof OrganizationMetadataSettings
        if (!settings.enabled) return memo
        const name =
          settings.label[locale] ?? Object.values(settings.label)[0] ?? '?'
        memo[filterKey] = {
          isAvailable: () => settings.enabled === true,
          name: name,
          icon: getCustomPropIcon(filterKey, settings.filterType),
          renderText: (value) => (
            <CustomPropFilterValueLabel filterKey={filterKey} value={value} />
          ),
          Component: (props) => (
            <CustomPropFilterComponent filterKey={filterKey} {...props} />
          ),
        } as FilterDefinition
        return memo
      },
      {},
    )
  }, [data, locale, enabled])
}

const AllKeys = Object.keys(DEFAULT_STATE) as FilterValueKey[]

type SegmentFilterKeysContextType = {
  activeKeys: FilterValueKey[]
  inactiveKeys: Set<FilterValueKey>
}
const SegmentFilterKeysContext = createContext<SegmentFilterKeysContextType>({
  activeKeys: AllKeys,
  inactiveKeys: new Set(),
})

type SegmentFilterKeysProviderProps = {
  children: React.ReactNode
} & (
  | {
      include?: FilterValueKey[]
      exclude?: never
    }
  | {
      include?: never
      exclude?: FilterValueKey[]
    }
)

const difference = (
  a: FilterValueKey[],
  b: FilterValueKey[],
): FilterValueKey[] => a.filter((x) => !b.includes(x))

export const SegmentFilterKeysProvider: React.FC<
  SegmentFilterKeysProviderProps
> = ({ include, exclude, children }) => {
  const context = React.useMemo<SegmentFilterKeysContextType>(() => {
    if (include !== undefined) {
      return {
        activeKeys: include,
        inactiveKeys: new Set(difference(AllKeys, include)),
      }
    }
    if (exclude !== undefined) {
      return {
        activeKeys: difference(AllKeys, exclude),
        inactiveKeys: new Set(exclude),
      }
    }
    return {
      activeKeys: AllKeys,
      inactiveKeys: new Set(),
    }
  }, [include, exclude])
  return (
    <SegmentFilterKeysContext.Provider value={context}>
      {children}
    </SegmentFilterKeysContext.Provider>
  )
}

export function useSegmentFilterKeys(): SegmentFilterKeysContextType {
  return useContext(SegmentFilterKeysContext)
}
