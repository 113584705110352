import request, { ResponseError } from '@capturi/request'
import { UseQueryResult, useQuery } from '@tanstack/react-query'

import {
  type OrganizationMetadataSettings,
  type OrganizationRepeatCallSettings,
  OrganizationSettings,
} from './models'

type Response = {
  organizationUid: string
  metadata: OrganizationMetadataSettings
  repeatCall: OrganizationRepeatCallSettings | null
}

export const useOrganizationSettings = (): UseQueryResult<
  OrganizationSettings,
  ResponseError
> =>
  useQuery({
    queryKey: ['organizationSettings'],
    queryFn: () =>
      request.get<Response>('organization/settings?api-version=3.3'),
    select: ({ metadata, organizationUid, repeatCall }) => {
      return {
        metadata,
        organizationUid,
        repeatCall: repeatCall || {
          maxDistance: 7 * 24 * 60 * 60,
          minDistance: 15 * 60,
        },
      }
    },
    staleTime: 10 * 60 * 1000,
  })
