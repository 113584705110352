import {
  CustomNumberPropSettings,
  CustomPropSettings,
  OrganizationMetadataSettings,
  useOrganizationSettings,
} from '@capturi/api-organization'
import { useCurrentUser } from '@capturi/core'
import React from 'react'

import { FilterValueLabel } from '../../components/FilterValueLabel'
import { Range } from './customNumberProp/types'
import { formatRange, getLocalizedLabel, isRangeMatch } from './utils'

export type CustomPropFilterValueLabelProps = {
  filterKey: keyof OrganizationMetadataSettings
  value?: unknown
}

export function CustomPropFilterValueLabel({
  filterKey,
  value,
}: CustomPropFilterValueLabelProps): React.ReactElement | null {
  const currentUser = useCurrentUser()
  const { data } = useOrganizationSettings()

  const settings = data?.metadata[filterKey]
  const locale = currentUser.language
  const name =
    settings != null ? getLocalizedLabel(settings.label, locale) ?? '?' : '?'

  let strValue: string | string[] | undefined = undefined
  if (filterKey.startsWith('customNumberProp')) {
    const settings = data?.metadata[filterKey] as CustomNumberPropSettings
    const rangeValue = value as Range | undefined
    if (settings?.filterType === 'Range') {
      strValue = formatRange(rangeValue?.min, rangeValue?.max)
    }
    if (settings?.filterType === 'Dropdown') {
      const option = settings.filters.find((x) => isRangeMatch(x, rangeValue))
      strValue = getLocalizedLabel(option?.label, locale) ?? '?'
    }
  } else {
    const settings = data?.metadata[filterKey] as CustomPropSettings
    if (settings?.filterType === 'Text') {
      strValue = Array.isArray(value) ? value[0] : value
    }
    if (settings?.filterType === 'Dropdown') {
      const strArrayValue = Array.isArray(value) ? value : [value]
      strValue = strArrayValue
        .map((value) => {
          const option = settings.values.find((x) => x.value === value)
          return getLocalizedLabel(option?.label, locale)
        })
        .filter((x): x is string => x !== undefined)
    }
  }

  return <FilterValueLabel name={name} value={strValue} />
}
