import { Button, RadioButton, RadioButtonGroup } from '@capturi/ui-components'
import { Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useMemo, useState } from 'react'

import { WordCloudItem } from '../../WordCloud'
import { KeyTopicsWordCloud } from '../KeyTopicsWordCloud'

type KeyTopicsWordCloudType =
  | 'Volume' // Conversations
  | 'Drivers' // Trends

export const DrawerWordCloud: React.FC<{
  wordCloud: { [key: string]: number } | undefined
  trendWordCloud: { [key: string]: number } | undefined
  itemsClasses?: string
  initialWordCloudType?: KeyTopicsWordCloudType
  selectedItemName?: string
  onSelectItem?: (item: WordCloudItem | undefined) => void
}> = ({
  initialWordCloudType = 'Volume',
  wordCloud,
  trendWordCloud,
  selectedItemName,
  onSelectItem,
}) => {
  const [wordCloudType, setWordCloudType] =
    useState<KeyTopicsWordCloudType>(initialWordCloudType)

  const items = useMemo(() => {
    return Object.entries(wordCloud ?? {}).map(([word, count]) => ({
      name: word,
      value: count,
    }))
  }, [wordCloud])

  const trendItems = useMemo(() => {
    return Object.entries(trendWordCloud ?? {}).map(([word, count]) => ({
      name: word,
      value: count,
    }))
  }, [trendWordCloud])

  if (items.length === 0 && trendItems.length === 0) {
    return null
  }

  return (
    <Flex flexDirection="column" w="100%" h="100%">
      <Flex alignItems="center" justifyContent="space-between" px={4}>
        <Text textColor="gray.600" fontSize="sm" fontWeight="medium">
          <Trans>Most frequent phrases</Trans>
        </Text>
        <RadioButtonGroup
          name="wordCloudType"
          onChange={(type: KeyTopicsWordCloudType) => setWordCloudType(type)}
          value={wordCloudType}
        >
          <RadioButton value="Volume">
            <Trans>Volume</Trans>
          </RadioButton>
          <RadioButton value="Drivers">
            <Trans>Drivers</Trans>
          </RadioButton>
        </RadioButtonGroup>
      </Flex>
      <Flex pb={selectedItemName ? 4 : 12} flexGrow={1}>
        <KeyTopicsWordCloud
          wordCloud={wordCloud}
          trendWordCloud={trendWordCloud}
          wordCloudType={wordCloudType}
          selectedItemName={selectedItemName}
          onSelectItem={onSelectItem}
        />
      </Flex>
      {selectedItemName && (
        <Flex justifyContent="end" px={4} flex={0}>
          <Button onClick={() => onSelectItem?.(undefined)} variant="ghost">
            <Trans>Reset</Trans>
          </Button>
        </Flex>
      )}
    </Flex>
  )
}
