import { Icon, IconButton, IconButtonProps, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { RiPlayList2Fill } from 'react-icons/ri'

export type PlaySnippetsButtonProps = Omit<IconButtonProps, 'aria-label'> & {
  label: string
}

export const PlaySnippetsButton: React.FC<PlaySnippetsButtonProps> = ({
  label,
  size = 'xs',
  colorScheme = 'primary',
  variant = 'lifted-icon',
  ...props
}) => {
  return (
    <Tooltip label={label} openDelay={300}>
      <IconButton
        aria-label={label}
        isRound
        icon={<Icon as={RiPlayList2Fill} boxSize="12px !important" />}
        variant={variant}
        colorScheme={colorScheme}
        size={size}
        {...props}
      />
    </Tooltip>
  )
}
