import { RepeatCall } from '@capturi/api-filters'
import { plural, t } from '@lingui/macro'
import React from 'react'
import { secondsToTimeValue } from './utils'

import { FilterValueLabel } from '../../components/FilterValueLabel'

const getLocalizedRepeatCallName = (
  type: RepeatCall['type'] | undefined,
): string => {
  if (type === undefined) {
    return t`Repeat calls`
  }

  switch (type) {
    case 'Causing':
      return t`Trigger calls`
    case 'Repeat':
      return t`Repeat calls`
    case 'None':
      return t`First call resolution`
  }
}

const getLocalizedRepeatCallLabel = (
  seconds: number | undefined,
): string | undefined => {
  if (seconds === undefined) {
    return undefined
  }

  const { value, timeUnit } = secondsToTimeValue(seconds)
  switch (timeUnit) {
    case 'days':
      return plural(value, {
        one: 'within # day',
        other: 'within # days',
      })
    case 'hours':
      return plural(value, {
        one: 'within # hour',
        other: 'within # hours',
      })
  }
}

export const RepeatCallsFilterValueLabel: React.FC<{ value?: RepeatCall }> = ({
  value,
}) => {
  return (
    <FilterValueLabel
      name={getLocalizedRepeatCallName(value?.type)}
      value={getLocalizedRepeatCallLabel(value?.value)}
    />
  )
}
