import { ConversationResponseModel } from '@capturi/api-conversations'
import { useOrganizationSettings } from '@capturi/api-organization'
import { useCurrentUser, useTeams } from '@capturi/core'
import { useFeatureFlags } from '@capturi/feature-flags'
import { getCustomPropIcon } from '@capturi/filters'
import { generateImageUrl } from '@capturi/request'
import { User, useOrganization } from '@capturi/stores'
import { PageHeading } from '@capturi/ui-components'
import {
  Avatar,
  AvatarGroup,
  Box,
  Flex,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Wrap,
  WrapItem,
  chakra,
  useToast,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React, { useMemo } from 'react'
import { IconType } from 'react-icons'
import { useCopyToClipboard } from 'react-use'

import SettingsMenu from '../components/SettingsMenu'
import StatusLabel from '../components/StatusLabel'

type Props = {
  conversation: ConversationResponseModel
  user?: User
  showGoToConversation: boolean
}

const formatDate = (date: Date): string =>
  i18n.date(date, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })

const Header: React.FC<Props> = ({
  conversation,
  user,
  showGoToConversation,
}) => {
  const { subject, customer, dateTime, externalIdentity } = conversation

  const currentUser = useCurrentUser()
  const { conversationLanguage } = useOrganization()
  const compare = useMemo(
    () => Intl.Collator(conversationLanguage).compare,
    [conversationLanguage],
  )

  const { showStatus, showExternalId } = useFeatureFlags()
  const { getTeamByUid } = useTeams()
  const team = getTeamByUid(conversation?.teamUid || '')

  const { data: organizationSettings } = useOrganizationSettings()

  const toast = useToast()
  const [, copy] = useCopyToClipboard()
  const handleCopyTag = (value: string | number): void => {
    copy(String(value))
    toast({ status: 'info', title: t`Copied ${value} to clipboard` })
  }

  const customPropTags = useMemo(
    () =>
      Object.entries(conversation.metadata)
        .reduce<{ label: string; value: string | number; icon: IconType }[]>(
          (memo, [key, value]) => {
            if (value == null) return memo
            const settings =
              organizationSettings?.metadata[
                key as keyof ConversationResponseModel['metadata']
              ]

            if (settings == null || !settings.enabled) return memo

            const label =
              settings.label[currentUser.language] ??
              Object.values(settings.label)[0]

            let mappedValue = value
            if (settings.filterType === 'Dropdown' && 'values' in settings) {
              const setting = settings.values.find((s) => s.value === value)
              if (setting?.label[currentUser.language]) {
                mappedValue = setting.label[currentUser.language]
              }
            }

            const icon = getCustomPropIcon(key, settings.filterType)
            memo.push({ icon, label, value: mappedValue })

            return memo
          },
          [],
        )
        .sort((a, b) => compare(a.label, b.label)),
    [
      compare,
      conversation.metadata,
      currentUser.language,
      organizationSettings?.metadata,
    ],
  )

  const labels = useMemo(
    () => conversation.labels.sort(compare),
    [compare, conversation.labels],
  )

  return (
    <Flex justifyContent="space-between" width="100%">
      <Box flex={1}>
        <Box>{subject && <PageHeading>{subject}</PageHeading>}</Box>
        <Wrap spacing={1} mt={2}>
          {showExternalId && externalIdentity != null && (
            <WrapItem>
              <Tag
                variant="outline"
                size="sm"
                onDoubleClick={() => handleCopyTag(externalIdentity)}
              >
                <TagLabel>{externalIdentity}</TagLabel>
              </Tag>
            </WrapItem>
          )}
          {showStatus && (
            <WrapItem>
              <StatusLabel status={conversation.status} />
            </WrapItem>
          )}
          {customPropTags.map((t, i) => (
            <WrapItem key={i}>
              <Tag onDoubleClick={() => handleCopyTag(t.value)}>
                <TagLeftIcon as={t.icon} />
                {t.label}:
                <chakra.span ml={1} fontWeight="normal">
                  {t.value}
                </chakra.span>
              </Tag>
            </WrapItem>
          ))}
          {labels.map((label) => (
            <WrapItem key={label}>
              <Tag
                variant="solid"
                colorScheme="secondary"
                onDoubleClick={() => handleCopyTag(label)}
              >
                <TagLabel>{label}</TagLabel>
              </Tag>
            </WrapItem>
          ))}
        </Wrap>
      </Box>
      <Box ml={4} flex="0 0 auto">
        <Flex direction="row" alignItems="center" gap={2}>
          <Box textAlign="right">
            <Flex align="center" justify="flex-end">
              {team && (
                <Tag
                  variant="outline"
                  color="gray.800"
                  size="sm"
                  mr={2}
                  onDoubleClick={() => handleCopyTag(team.name)}
                >
                  <TagLabel>{team.name}</TagLabel>
                </Tag>
              )}
              <Box>{user?.name}</Box>
            </Flex>
            <Flex justifyContent={'flex-end'}>
              <Text
                color="textMuted"
                onDoubleClick={() => handleCopyTag(customer)}
              >
                {customer}
              </Text>
              <Text color="textMuted" mx="1">
                |
              </Text>
              <Text
                color="textMuted"
                onDoubleClick={() => handleCopyTag(formatDate(dateTime))}
              >
                {dateTime && formatDate(dateTime)}
              </Text>
            </Flex>
          </Box>
          <AvatarGroup size="md">
            <Avatar
              key={user?.uid}
              size="sm"
              src={generateImageUrl(user?.profileImage?.key, { size: 44 })}
              name={user?.name}
            />
          </AvatarGroup>
          {!conversation.deleted && (
            <SettingsMenu
              conversationUid={conversation.uid}
              showGoToConversation={showGoToConversation}
            />
          )}
        </Flex>
      </Box>
    </Flex>
  )
}

export default Header
