import { SentimentScore, SentimentSpeaker } from '@capturi/api-sentiment'
import { select, t } from '@lingui/macro'

export function scoreToString(score?: SentimentScore): string | undefined {
  switch (score) {
    case 'Low':
      return t`Low`
    case 'Medium':
      return t`Medium`
    case 'High':
      return t`High`
    default:
      return undefined
  }
}

export function speakerToString(
  orgType: string,
  speaker?: SentimentSpeaker,
): string | undefined {
  switch (speaker) {
    case 'User':
      return t`Employee`
    case 'Other':
      return select(orgType, {
        public: 'Citizen',
        other: 'Customer',
      })
    case 'Combined':
      return t`All`
    default:
      return undefined
  }
}
