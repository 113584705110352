import { alertAnatomy as parts } from '@chakra-ui/anatomy'
import { theme } from '@chakra-ui/theme'
import type {
  PartsStyleFunction,
  StyleFunctionProps,
} from '@chakra-ui/theme-tools'

const originalVariants = theme.components.Alert.variants

type Status = 'info' | 'warning' | 'success' | 'error'

function withRemappedStatusColor(
  variantFn?: PartsStyleFunction<typeof parts>,
): PartsStyleFunction<typeof parts> | undefined {
  if (variantFn == null) return undefined

  // Default color scheme for alerts
  // Need this to see if the user passed in a colorScheme
  // as the colorScheme will be set by the base Alert config.
  const DEFAULT_COLOR_SCHEMES: { [status in Status]: string } = {
    info: 'blue',
    warning: 'orange',
    success: 'green',
    error: 'red',
  }

  // Custom colorScheme for alerts
  const STATUSES: { [status in Status]: Partial<StyleFunctionProps> } = {
    info: { colorScheme: 'primary' },
    warning: { colorScheme: 'orange' },
    success: { colorScheme: 'green' },
    error: { colorScheme: 'red' },
  }

  return (props) => {
    const status: Status = props.status ?? 'info'
    if (props.colorScheme === DEFAULT_COLOR_SCHEMES[status]) {
      return variantFn({
        ...props,
        ...STATUSES[status],
      })
    }
    return variantFn(props)
  }
}

export default {
  variants: {
    subtle: withRemappedStatusColor(originalVariants?.subtle),
    'left-accent': withRemappedStatusColor(originalVariants?.['left-accent']),
    'top-accent': withRemappedStatusColor(originalVariants?.['top-accent']),
    solid: withRemappedStatusColor(originalVariants?.solid),
  },
}
