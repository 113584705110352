import { useCurrentUser } from '@capturi/core'
import { Box, Flex, IconButton, Input } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import { MdSave } from 'react-icons/md'

import { useFilteredOrgs } from './useFilteredOrgs'

type Props = {
  orgs: { name: string; id: string }[]
  current: string
  handleChangeOrg: (uid: string) => void
  inputRef: React.RefObject<HTMLInputElement>
}

const STORAGE_KEY = 'organizationNeedle'

const OrganizationChooserList: React.FC<Props> = ({
  current,
  orgs,
  handleChangeOrg,
  inputRef,
}) => {
  const { hasSuperPowers } = useCurrentUser()
  const [needle, setNeedle] = useState(localStorage.getItem(STORAGE_KEY) || '')
  const filteredOrgs = useFilteredOrgs(orgs, needle)
  const [saveNeedle, setSaveNeedle] = useState(!!needle)

  const handleChange = (n: string): void => {
    if (saveNeedle) localStorage.setItem(STORAGE_KEY, n)

    setNeedle(n)
  }
  const handleSaveNeedle = (): void => {
    if (saveNeedle) {
      localStorage.removeItem(STORAGE_KEY)
      setSaveNeedle(false)
    } else {
      localStorage.setItem(STORAGE_KEY, needle)
      setSaveNeedle(true)
    }
  }

  return (
    <Box
      fontSize="md"
      maxH="min(800px, 70vh)"
      overflowY="auto"
      minH="300px"
      p="2"
    >
      <Flex alignItems="center" mb="4">
        <Input
          placeholder={t`Search...`}
          ref={inputRef}
          value={needle}
          onChange={(e) => handleChange(e.currentTarget.value)}
        />
        {hasSuperPowers && (
          <IconButton
            variant="ghost"
            colorScheme={saveNeedle ? 'primary' : 'gray'}
            onClick={handleSaveNeedle}
            icon={<MdSave />}
            aria-label="save search term"
          />
        )}
      </Flex>
      {filteredOrgs.length === 0 ? (
        <Flex alignItems="center" height="300px" justifyContent="center">
          <Trans>No results</Trans>
        </Flex>
      ) : (
        filteredOrgs.map((o) => (
          <Box
            px="3"
            py="1.5"
            key={o.id}
            onClick={current === o.id ? undefined : () => handleChangeOrg(o.id)}
            fontWeight={current === o.id ? 'bold' : undefined}
            cursor={current === o.id ? 'default' : 'pointer'}
            transition="background 200ms"
            _hover={{ bg: 'gray.100' }}
          >
            {o.name}
          </Box>
        ))
      )}
    </Box>
  )
}

export default OrganizationChooserList
