import { RepeatCall } from '@capturi/api-filters'
import { useOrganizationSettings } from '@capturi/api-organization'
import { Button } from '@capturi/ui-components'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'

import { FilterCriteriaComponentBaseProps } from '../../components/PhoneFilter'
import { MIN_REPEAT_CALL_DISTANCE } from '../constants'
import RepeatCallsCriteria from './RepeatCallsCriteria'
import { RepeatCallsRadioGroup } from './RepeatCallsRadioGroup'
import { secondsToTimeValue, timeValueToSeconds } from './utils'

export type RepeatCallProps = FilterCriteriaComponentBaseProps<RepeatCall>

const getLocalizedRepeatCallType = (type: RepeatCall['type']): string => {
  switch (type) {
    case 'Causing':
      return t`Repeat call should be within`
    case 'Repeat':
      return t`Trigger call should be within`
    case 'None':
      return t`No subsequent or prior calls within`
  }
}

type RepeatCallsState =
  | {
      type: 'Causing' | 'Repeat'
      value: number
      timeUnit: 'days' | 'hours'
      min: number
    }
  | {
      type: 'None'
      value: number
      timeUnit: 'days' | 'hours'
      min: null
    }

const getRepeatCallState = (
  type: RepeatCall['type'],
  value: number,
  timeUnit: 'days' | 'hours',
  min: number,
): RepeatCallsState => {
  if (type === 'None') {
    return { type: 'None', value, timeUnit, min: null }
  }
  return { type, value, timeUnit, min }
}

export const RepeatCalls: React.FC<RepeatCallProps> = (props) => {
  const { data } = useOrganizationSettings()
  const initialType = props.value?.type ?? 'Repeat'
  const minValue = data?.repeatCall.minDistance ?? MIN_REPEAT_CALL_DISTANCE //This value defaults to 15 minutes (in seconds for api), until the value can be set properly in the org config (see figma)
  const maxValue = props.value?.value ?? data?.repeatCall.maxDistance
  const convertedValue = secondsToTimeValue(maxValue ?? 86400) // defaults to 1 day
  const [state, setState] = useState<RepeatCallsState>(
    getRepeatCallState(
      initialType,
      convertedValue.value,
      convertedValue.timeUnit,
      minValue,
    ),
  )

  const handleOk = (): void => {
    props.setValue?.({
      type: state.type,
      value: timeValueToSeconds(state.value, state.timeUnit),
      min: state.min,
    })
    props.onClose?.()
  }

  const onCriteriaChange = (value: number, timeUnit: 'days' | 'hours') => {
    const newState = getRepeatCallState(state.type, value, timeUnit, minValue)
    setState(newState)
  }

  const onTypeChange = (type: RepeatCall['type']) => {
    const newState = getRepeatCallState(
      type,
      state.value,
      state.timeUnit,
      minValue,
    )
    setState(newState)
  }
  return (
    <Flex flexDir="column">
      <VStack p={4} gap={4} alignItems="flex-start">
        <Text fontWeight="medium">
          <Trans>Repeat calls</Trans>
        </Text>
        <RepeatCallsRadioGroup value={state.type} onChange={onTypeChange} />
        <RepeatCallsCriteria
          onChange={onCriteriaChange}
          value={{ value: state.value, timeUnit: state.timeUnit }}
          title={getLocalizedRepeatCallType(state.type)}
        />
      </VStack>
      <HStack
        pt={3}
        mb={3}
        mt={4}
        boxShadow="0px -4px 8px -6px rgba(0, 0, 0, 0.20)"
        justify="flex-end"
      >
        <Button onClick={handleOk} size="xs" primary variant="ghost">
          <Trans>OK</Trans>
        </Button>
      </HStack>
    </Flex>
  )
}
