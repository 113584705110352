import { AiInsights } from '@capturi/api-conversations'
import { Box, Flex, Icon, Text, Tooltip } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/macro'
import React from 'react'
import { MdInfoOutline } from 'react-icons/md'

import AiContentCard from './AiContentCard'

const AiOverview: React.FC<{
  conversationUid: string
  aiInsights: AiInsights | null
}> = ({ conversationUid, aiInsights }) => {
  const hasTopics = aiInsights?.keyTopic && aiInsights?.keySubTopic
  return (
    <Flex flex="1 0 50%">
      <Flex flexDir="column" flexGrow="1" gap={2}>
        <Text fontWeight="medium" fontSize="sm">
          <Trans>Capturi AI</Trans>
        </Text>
        <Box mb={4} pos="relative">
          <AiContentCard
            conversationUid={conversationUid}
            content={{
              header: {
                title: (
                  <Tooltip
                    label={
                      <Text>
                        <Trans>
                          The key topic and the call reason description are
                          derived from the{' '}
                          <Text as="span" textDecor="underline">
                            beginning
                          </Text>{' '}
                          of the call.
                        </Trans>
                      </Text>
                    }
                    hasArrow
                    placement="top"
                  >
                    <Text as="span">
                      <Trans>
                        Key topic
                        <Icon ml="1" as={MdInfoOutline} />
                      </Trans>
                    </Text>
                  </Tooltip>
                ),
                subTitle: t`Call reason description`,
                topics: hasTopics
                  ? `${aiInsights.keyTopic} > ${aiInsights.keySubTopic}`
                  : t`Details not available.`,
              },
              body: aiInsights?.rootCause ?? t`Details not available.`,
            }}
          />
        </Box>
        <Box pos="relative">
          <AiContentCard
            conversationUid={conversationUid}
            content={{
              header: {
                title: (
                  <Tooltip
                    label={t`This section contains additional information about the call. The content can be adapted to your needs. Contact your Customer Success Manager if you want to add or remove information from this section.`}
                    hasArrow
                    placement="top"
                  >
                    <Text as="span">
                      <Trans>
                        Additional details
                        <Icon ml="1" as={MdInfoOutline} />
                      </Trans>
                    </Text>
                  </Tooltip>
                ),
              },
              body: aiInsights?.customSummary ?? t`Details not available.`,
            }}
          />
        </Box>
      </Flex>
    </Flex>
  )
}

export default AiOverview
