import {
  CaseListIncludingTrackerHitsResponseModel,
  useCasesIncludingTrackerHits,
} from '@capturi/api-cases'
import {
  TextFilterValues,
  mapTextFilterValuesToRequestModel,
} from '@capturi/api-filters'
import { BaseTracker } from '@capturi/api-trackers'
import {
  FilterPeriodProvider,
  PeriodDefinition,
  useFilterPeriodContext,
} from '@capturi/filters'
import { useUsers } from '@capturi/stores'
import { Highlight } from '@capturi/ui-components'
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  HStack,
  List,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import { useCaseDetailsDrawer } from 'components/CaseDetailsDrawer'
import React, { useCallback, useMemo } from 'react'
import { useTimeout } from 'react-use'

const dateFormat: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

type DataProps = {
  tracker: BaseTracker
  phrase?: string
  filterState: TextFilterValues
  periodDef: PeriodDefinition
}

export type EmailTrackerExamplesDrawerProps = DataProps &
  Omit<DrawerProps, 'children'>

export const EmailTrackerExamplesDrawer: React.FC<
  EmailTrackerExamplesDrawerProps
> = ({ tracker, phrase, filterState, periodDef, isOpen, onClose }) => {
  const [isReady] = useTimeout(500)
  return (
    <FilterPeriodProvider defaultPeriod={periodDef}>
      <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader
            borderBottom="1px"
            borderBottomColor="gray.200"
            lineHeight={1.4}
            display="flex"
            alignItems="center"
            pr={10}
            pl={4}
          >
            <Box flex="1">
              <Text fontSize="sm" color="textMuted">
                <Trans>Conversations</Trans>
              </Text>
              <Text fontSize="1rem">{tracker.name}</Text>
            </Box>
          </DrawerHeader>
          <DrawerBody overflow="hidden" p={0}>
            {isReady() && (
              <DrawerBodyContent
                filter={filterState}
                tracker={tracker}
                phrase={phrase}
              />
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </FilterPeriodProvider>
  )
}

const DrawerBodyContent: React.FC<{
  filter: TextFilterValues
  tracker: BaseTracker
  phrase?: string
}> = ({ filter, tracker, phrase }) => {
  const { period } = useFilterPeriodContext()

  const filterRequestModel = useMemo(
    () => mapTextFilterValuesToRequestModel(filter),
    [filter],
  )
  const {
    data,
    isInitialLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useCasesIncludingTrackerHits({
    trackerUid: tracker.uid,
    phrase,
    filter: filterRequestModel,
    period,
  })

  const handleLoadMore = useCallback((): void => {
    fetchNextPage()
  }, [fetchNextPage])

  return (
    <Box h="full" overflowX="hidden">
      <List px={4}>
        {(data?.pages ?? []).map((d) =>
          d?.map((c) =>
            c.messages.map((m) => (
              <CaseTrackerHits
                key={`${c.uid}-${m.uid}`}
                caseUid={c.uid}
                trackerUid={tracker.uid}
                subject={c.subject}
                message={m}
                trackerHits={c.trackerHits}
              />
            )),
          ),
        )}
      </List>
      {!isInitialLoading && (
        <Flex my={4} justify="center" w="100%">
          <Button
            onClick={handleLoadMore}
            isDisabled={!hasNextPage || isFetchingNextPage}
            isLoading={isFetchingNextPage}
            borderRadius="md"
            size="sm"
          >
            {!hasNextPage ? (
              <Trans>No more tickets</Trans>
            ) : (
              <Trans>Load more</Trans>
            )}
          </Button>
        </Flex>
      )}
    </Box>
  )
}

type CaseWithTrackerHitsProps = {
  caseUid: string
  trackerUid: string
  subject: string
  message: CaseListIncludingTrackerHitsResponseModel['messages'][0]
  trackerHits: CaseListIncludingTrackerHitsResponseModel['trackerHits']
}

type TrackerMessageHit = {
  key: string
  trackerUid: string
  messageUid: string
  text: React.ReactNode
  from: number
  to: number
}

function extractText(
  textOrNull: string | null,
  from: number,
  to: number,
): React.ReactNode {
  const text = textOrNull || ''
  const textArr = [...text]
  const preText = textArr.slice(from - 30, from).join('')
  const highlightedText = textArr.slice(from, to + 1).join('')
  const postText = textArr.slice(to + 1, to + 50 + 1).join('')
  return (
    <Text noOfLines={1}>
      {preText}
      <Highlight>{highlightedText}</Highlight>
      {postText}
    </Text>
  )
}

const CaseTrackerHits: React.FC<CaseWithTrackerHitsProps> = ({
  caseUid,
  trackerUid,
  subject,
  message,
  trackerHits,
}) => {
  const { getUserByUid } = useUsers()
  const trackerHitsUids = useMemo(
    () => new Set(trackerHits.map((x) => x.trackerUid)),
    [trackerHits],
  )

  const flattenedMessageHits = useMemo(() => {
    const messageTrackerHits = message.trackerHits?.filter((x) => {
      return trackerHitsUids.has(x.trackerUid)
    })
    return messageTrackerHits?.flatMap((x) => {
      return Object.keys(x.phrases).flatMap((key) => {
        const phrases = x.phrases[key]
        return phrases.map((phrase) => {
          return {
            ...phrase,
            trackerUid: x.trackerUid,
            key,
          }
        })
      })
    })
  }, [message.trackerHits, trackerHitsUids])

  const hits = useMemo(() => {
    return (
      flattenedMessageHits?.reduce<TrackerMessageHit[]>((acc, x) => {
        if (x.field === 'Subject') {
          acc.push({
            key: `subject-${x.from}-${x.to}`,
            text: extractText(message.subject, x.from, x.to),
            from: x.from,
            to: x.to,
            trackerUid: x.trackerUid,
            messageUid: message.uid,
          })
        } else if (x.field === 'Text') {
          acc.push({
            key: `text-${x.from}-${x.to}`,
            text: extractText(message.text, x.from, x.to),
            from: x.from,
            to: x.to,
            trackerUid: x.trackerUid,
            messageUid: message.uid,
          })
        }
        return acc
      }, []) ?? []
    )
  }, [flattenedMessageHits, message.subject, message.text, message.uid])

  const openCaseDetailsDrawer = useCaseDetailsDrawer()
  const handleOpenCaseDetailsDrawer = useCallback(() => {
    openCaseDetailsDrawer({
      caseUid,
      trackerUid,
    })
  }, [openCaseDetailsDrawer, caseUid, trackerUid])

  const { name, email } = message.userUid
    ? getUserByUid(message.userUid)
    : message.from

  return (
    <Card
      borderTopRadius="sm"
      borderBottomRadius="lg"
      borderColor="gray.200"
      cursor="pointer"
      overflow="hidden"
      my={4}
    >
      <CardHeader
        bg="gray.200"
        px={4}
        py={2}
        onClick={handleOpenCaseDetailsDrawer}
        data-group
      >
        <VStack align="start">
          <HStack justifyContent="space-between" w="100%">
            <Text
              fontWeight="medium"
              _groupHover={{ textDecoration: 'underline' }}
            >
              {subject}
            </Text>
            <Text>{name}</Text>
          </HStack>
          <HStack justifyContent="space-between" w="100%">
            <Text noOfLines={1} flexShrink={0}>
              {`${message.type} | ${i18n.date(message.created, dateFormat)}`}
            </Text>
            <Text noOfLines={1}>{email}</Text>
          </HStack>
        </VStack>
      </CardHeader>

      <CardBody p={0}>
        <List>
          {hits.map((hit) => {
            return (
              <ListItem
                borderBottom="1px"
                borderColor="gray.200"
                key={hit.key}
                onClick={() => {
                  openCaseDetailsDrawer({
                    caseUid,
                    trackerUid: hit.trackerUid,
                    messageUid: hit.messageUid,
                  })
                }}
                p={4}
                _hover={{ background: 'gray.50' }}
                _last={{
                  borderBottom: '0px',
                }}
              >
                {hit.text}
              </ListItem>
            )
          })}
        </List>
      </CardBody>
    </Card>
  )
}
