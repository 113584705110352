import { OrganizationMetadataSettings } from '@capturi/api-organization'
import { IconType } from 'react-icons/lib'
import { MdLooks5, MdStyle, MdTextFields, MdTimelapse } from 'react-icons/md'

export function getCustomPropIcon(
  filterKey: string,
  filterType: OrganizationMetadataSettings[keyof OrganizationMetadataSettings]['filterType'],
): IconType {
  switch (filterType) {
    case 'Dropdown': {
      if (filterKey.startsWith('customNumberProp')) {
        return MdLooks5
      }
      return MdStyle
    }
    case 'Range':
      return MdTimelapse
    case 'Text':
      return MdTextFields
  }
}
