import { mix } from 'color2k'

const mixAmount = 0.3
export const keySubTopicColors = ['#216D97', '#709966', '#F59F00']

export const chartColors = [
  mix(keySubTopicColors[0], '#ffffffff', mixAmount),
  mix(keySubTopicColors[1], '#ffffffff', mixAmount),
  mix(keySubTopicColors[2], '#ffffffff', mixAmount),
]
