import { useTheme } from '@capturi/ui-theme'
import { Flex } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { FC, useCallback, useMemo } from 'react'
import { WordCloud, WordCloudItem } from '../WordCloud'

export type KeyTopicsWordCloudType =
  | 'Volume' // Conversations
  | 'Drivers' // Trends

export const KeyTopicsWordCloud: FC<{
  wordCloud: { [key: string]: number } | undefined
  trendWordCloud: { [key: string]: number } | undefined
  itemsClasses?: string
  wordCloudType: KeyTopicsWordCloudType
  selectedItemName?: string
  onSelectItem?: (item: WordCloudItem | undefined) => void
}> = ({
  wordCloudType,
  wordCloud,
  trendWordCloud,
  selectedItemName,
  onSelectItem,
}) => {
  const theme = useTheme()
  const items = useMemo(() => {
    return Object.entries(wordCloud ?? {}).map(([word, count]) => ({
      name: word,
      value: count,
    }))
  }, [wordCloud])

  const trendItems = useMemo(() => {
    return Object.entries(trendWordCloud ?? {}).map(([word, count]) => ({
      name: word,
      value: count,
    }))
  }, [trendWordCloud])

  const fill = useCallback(() => {
    return wordCloudType === 'Volume'
      ? 'url(#volume-fill-gradient)'
      : 'url(#drivers-fill-gradient)'
  }, [wordCloudType])

  return (
    <Flex
      flex={1}
      p={4}
      overflow="hidden"
      css={css`
        text:hover {
          cursor: pointer;
          filter: brightness(85%);
          opacity: 1;
          text-shadow: ${theme.shadows.textExtended} !important;
        }
        text.selected {
          text-shadow: ${theme.shadows.textExtended} !important;
          stroke: ${theme.colors.curiousBlue[500]} !important;
          stroke-width: 1px !important;
        }
        text.dimmed {
          opacity: 0.4 !important;
        }
      `}
    >
      <svg width="0" height="0" style={{ position: 'absolute' }}>
        <defs>
          {wordCloudType === 'Volume' ? (
            <linearGradient
              id="volume-fill-gradient"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop offset="0%" stopColor="#27829C" />
              <stop offset="33%" stopColor="#1B4963" />
              <stop offset="66%" stopColor="#705257" />
              <stop offset="100%" stopColor="#0A454D" />
            </linearGradient>
          ) : (
            <linearGradient
              id="drivers-fill-gradient"
              x1="100%"
              y1="100%"
              x2="0%"
              y2="0%"
            >
              <stop offset="2.67%" stopColor="#FFB757" />
              <stop offset="96.05%" stopColor="#ED54B4" />
              <stop offset="23.47%" stopColor="rgba(244, 255, 5, 0.00)" />
              <stop offset="47.05%" stopColor="rgba(253, 99, 210, 0.06)" />
              <stop offset="78.99%" stopColor="rgba(56, 255, 231, 0.00)" />
              <stop offset="17.32%" stopColor="rgba(255, 65, 5, 0.00)" />
              <stop offset="48.33%" stopColor="rgba(255, 218, 121, 0.03)" />
              <stop offset="61.28%" stopColor="rgba(58, 243, 255, 0.06)" />
              <stop offset="85.76%" stopColor="rgba(56, 207, 255, 0.00)" />
              <stop offset="0%" stopColor="#FFF" />
              <stop offset="100%" stopColor="#FAFAFA" />
            </linearGradient>
          )}
        </defs>
      </svg>
      <WordCloud
        items={wordCloudType === 'Volume' ? items : trendItems}
        fill={fill}
        selectedItemName={selectedItemName}
        handleClick={onSelectItem}
      />
    </Flex>
  )
}
